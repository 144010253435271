import { useCallback } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import debounce from "lodash/debounce";
import { Icon } from "@circle-react-shared/Icon";

export const DebouncedSearchBar = ({
  defaultValue,
  onSearch,
  placeholder = t("search_item"),
  wait = 300,
  withIcon = true,
}) => {
  const debouncedSearch = useCallback(
    debounce(value => onSearch(value), wait),
    [onSearch, wait],
  );

  return (
    <label className="m-0 flex flex-1 items-center gap-1">
      {withIcon && (
        <Icon className="text-dark inline-block" type="16-search" size={16} />
      )}
      <input
        className="bg-primary text-dark placeholder:text-light w-full px-2 py-1 placeholder:opacity-100 focus:outline-none"
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={event => debouncedSearch(event.target.value)}
        onKeyDown={event => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      />
    </label>
  );
};

DebouncedSearchBar.propTypes = {
  defaultValue: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  wait: PropTypes.number,
  withIcon: PropTypes.bool,
};
