import { useState } from "react";

export const Carousel = ({
  cards,
  renderCardsOnpage,
  cardsPerPage,
  cardsHaveNextPage,
  fetchCards,
}) => {
  const [page, setPage] = useState(1);

  const handPreviousClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = async () => {
    let nextPage = page + 1;
    if (page * cardsPerPage + 1 > cards.length) {
      if (cardsHaveNextPage) {
        await fetchCards({ page: nextPage });
      } else {
        nextPage = 1;
      }
    }
    setPage(nextPage);
  };

  const startIndex = () => (page > 1 ? (page - 1) * cardsPerPage : 0);

  return (
    <div className="carousel__items clearfix">
      {renderCardsOnpage({
        fromIndex: startIndex(),
        toIndex: startIndex() + cardsPerPage,
      })}
      {page > 1 && (
        <button
          type="button"
          className="carousel__nav carousel__nav--previous"
          onClick={() => handPreviousClick()}
        >
          {"<"}
        </button>
      )}
      <button
        type="button"
        className="carousel__nav carousel__nav--next"
        onClick={() => handleNextClick()}
      >
        {">"}
      </button>
    </div>
  );
};
