import { t } from "i18n-js";
import { useMutation } from "react-query";
import { useQuery, useQueryClient } from "react-query";
import { paywallSubscriptionsApi } from "@/react/api/paywallSubscriptionsApi";
import { useToast } from "@circle-react-uikit/ToastV2";

const { cancelAndRefundSubscription, refundOptionsSubscription } =
  paywallSubscriptionsApi;

export const usePaywallRefundSubscriptionApi = ({ subscription, callback }) => {
  const { success, error } = useToast();
  const defaultErrorMessage = t(
    "settings.paywalls_admin_dashboard.subscriptions.alerts.error.action_failed",
  );

  const queryClient = useQueryClient();
  const queryKeyString = `paywall-subscription-${subscription.processor_id}-refund-options`;
  const fetchRefundOptionsSubscription = useQuery(
    queryKeyString,
    () =>
      refundOptionsSubscription({
        subscriptionProcessorId: subscription.processor_id,
      }),
    {
      refetchOnMount: false,
      onError: () => {
        const defaultValue = {
          prorated_applicable: true,
          full_refund_applicable: true,
        };
        queryClient.setQueryData(queryKeyString, () => defaultValue);
      },
    },
  );

  const refundMutation = useMutation(
    params =>
      cancelAndRefundSubscription({
        subscriptionProcessorId: subscription.processor_id,
        params,
      }),
    {
      onSuccess: () => {
        callback.cancelAndRefundSubscription.onSuccess();
        success(
          t(
            "settings.paywalls_admin_dashboard.subscriptions.alerts.success.updated",
          ),
        );
      },
      onError: updateError => {
        callback.cancelAndRefundSubscription.onError();
        console.error(updateError?.message);
        error(updateError?.message ?? defaultErrorMessage, {
          duration: "long",
        });
      },
    },
  );

  return {
    refundMutation,
    fetchRefundOptionsSubscription,
  };
};
