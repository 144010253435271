import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";

export function useUpgradeLogic() {
  const { changePlanModalParams } = usePlanStatus();
  const { refetch: refetchPundit } = usePunditUserContext();
  const upgradePlanModal = useUpgradePlanModal();

  const onCloseModal = async () => {
    await refetchPundit();
  };

  const onSuccess = async () => {
    await refetchPundit();
  };

  const showUpgradeModal = async ({ minimumRequiredTier }: any) => {
    const planTierOrId = changePlanModalParams(minimumRequiredTier);
    const { planTier, planId } = planTierOrId;

    await upgradePlanModal.show({
      planId: planId,
      planTier: planTier,
      onSuccess,
      subheading: t(
        "pricing_plans.upgrade_modal.subheadings.analytics_filters",
      ),
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_analytics",
      ),
      showPlanDetails: true,
      onCloseModal,
      source: UPGRADE_FLOW_SOURCES.ANALYTICS_FILTERS,
    });
  };

  return { showUpgradeModal };
}
