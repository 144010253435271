import { t } from "i18n-js";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import type { ModalSizeType } from "@circle-react-shared/uikit/ModalV2/ModalContent";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

const LOCALE_ROOT = "export_paywall_csv.confirm_export_modal";

interface ConfirmExportModalProps {
  email: string;
  size?: ModalSizeType;
}

export const ConfirmExportModal = ({
  email,
  size = "md",
}: ConfirmExportModalProps) => {
  const modal = useModal();
  const onClose = () => {
    window.open(settingsNavPath.paywalls.bulk_logs, "_blank");
    void modal.hide();
  };

  return (
    <Modal isOpen={modal.visible} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content size={size}>
        <Modal.Header> </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-y-4">
            <Icon type="circle-check--green" className="!w-20" />
            <div className="flex flex-col gap-y-4 text-center">
              <Typography.TitleLg>
                {t([LOCALE_ROOT, "title"])}
              </Typography.TitleLg>
              {t([LOCALE_ROOT, "description"], { email })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button full large variant="circle" onClick={onClose}>
            {t("done")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const useConfirmExportModal = () =>
  useModal(ModalManager.create(ConfirmExportModal));
