import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const CouponAppliedProperty = ({ charge }: any) => {
  const couponAppliedString = charge.paywall_coupon_redemption
    ? `${charge.paywall_coupon_redemption.paywall_coupon.name} / ${charge.paywall_coupon_redemption.paywall_coupon.terms}`
    : t(`${localeStringRoot}.no_coupon_applied`);

  return (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.coupon_applied`)}
    >
      <Typography.LabelSm>{couponAppliedString}</Typography.LabelSm>
    </PropertyLine>
  );
};
