import { t } from "i18n-js";

export const PlatformColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  return (
    <div>
      {t(
        `settings.paywalls_admin_dashboard.subscriptions.platform.${subscription.platform}`,
      )}
    </div>
  );
};
