import classNames from "classnames";
import { useSidebarBackgroundStyles } from "@circle-react/components/Spaces/SidebarV2/hooks";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export const SwitcherCount = () => {
  const { isV3Enabled } = useIsV3();
  const { backgroundColor: borderColor } = useSidebarBackgroundStyles();

  return (
    <span
      style={{ borderColor }}
      className={classNames("switcher__count", {
        "!-right-0.5 !-top-0.5": isV3Enabled,
      })}
    />
  );
};
