import { t } from "i18n-js";
import * as yup from "yup";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { Form } from "@circle-react-uikit/Form";
import { ExportCsvModalContext } from "./ExportCsvModalContext";
import { FieldsForm } from "./FieldsForm";
import { FilterAppliedCallout } from "./FilterAppliedCallout";
import { TimezoneOptionsForm } from "./TimezoneOptionsForm";
import { useExportCsvModalHooks } from "./hooks/useExportCsvModalHooks";

const validationSchema = yup.object().shape({
  fields: yup.array().min(1),
});

export const ExportCsvModal = ({ relation }: any) => {
  const { modal, onClose, onSubmit, formId, isLoading } =
    useExportCsvModalHooks({ relation });

  return (
    <ExportCsvModalContext.Provider value={{ relation, isLoading }}>
      <ConfirmationModal
        cancelText={t("cancel")}
        confirmText={t("confirm")}
        confirmVariant="circle"
        onClose={onClose}
        onCancel={onClose}
        onConfirm={() => {}}
        isOpen={modal.visible}
        title={t(`export_paywall_csv.modal.title.${relation}`)}
        titleSize="md"
        formId={formId}
      >
        <Form
          id={formId}
          onSubmit={onSubmit}
          defaultValues={{}}
          formClassName="flex flex-col gap-y-4"
          validationSchema={validationSchema}
        >
          <TimezoneOptionsForm />
          <FieldsForm />
        </Form>
        <FilterAppliedCallout />
      </ConfirmationModal>
    </ExportCsvModalContext.Provider>
  );
};

export const useExportCsvModal = () =>
  useModal(ModalManager.create(ExportCsvModal));
