import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { t } from "i18n-js";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../../Filter";
import { BaseFilterFields } from "../BaseFilterFields";
import { SearchBar } from "../CheckboxFilter";
import type { SearchBarProps } from "../CheckboxFilterV2/CheckboxFilter";
import { SelectFilterOption } from "./SelectFilterOption";
import type { SingleSelectFilterOption } from "./SelectFilterOption";

export interface SingleSelectFilterParam extends BaseFilter {
  value?: string;
}

export const getIsSingleSelectFilterParam = (
  param: BaseFilter,
): param is SingleSelectFilterParam => typeof param.value === "string";

interface SingleSelectFilterProps {
  options: SingleSelectFilterOption[];
  param: BaseFilter;
  legend: string;
  searchProps?: SearchBarProps;
  hasSearch?: boolean;
}

/**
 * This component is very similar to the Radio button component except that the UI representation is different.
 */
export function SingleSelectFilter({
  param,
  legend,
  options,
  hasSearch = false,
  searchProps,
}: SingleSelectFilterProps) {
  const { onApply } = useFilterContext();
  const primaryButtonVariant = useButtonVariant();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = options.filter(option =>
    !searchTerm
      ? option
      : option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const getDefaultValues = () => ({
    ...param,
    value: getIsSingleSelectFilterParam(param) ? param.value : undefined,
  });
  const [selectedValue, setSelectedValue] = useState(getDefaultValues().value);

  const handleApply = (submitData: SingleSelectFilterParam, { reset }: any) => {
    onApply({ ...submitData, value: selectedValue });
    reset();
  };

  return (
    <div className="flex flex-col gap-y-3 py-4">
      {hasSearch && (
        <div className="px-4">
          <SearchBar
            name="search-bar"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            searchProps={searchProps}
          />
        </div>
      )}
      <Form defaultValues={getDefaultValues()} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-4">
          <RadioGroup
            value={selectedValue}
            onChange={setSelectedValue}
            name="value"
          >
            <RadioGroup.Label className="sr-only">{legend}</RadioGroup.Label>
            <div className="flex max-h-[20rem] flex-col overflow-y-auto">
              {filteredOptions.map(option => (
                <SelectFilterOption key={option.value} option={option} />
              ))}
            </div>
          </RadioGroup>
          <BaseFilterFields />
          <div className="px-4">
            <Button variant={primaryButtonVariant} type="submit" full>
              {t("filters.apply")}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
