import {
  isPaymentMethodTypeApplePay,
  isPaymentMethodTypeCard,
  isPaymentMethodTypeGooglePay,
  isPaymentMethodTypeLink,
  isPaymentMethodTypeNull,
  isPaymentMethodTypePix,
} from "@circle-react/helpers/communityMemberChargeHelpers";
import { CardProperty } from "./CardProperty";
import { PixProperty } from "./PixProperty";

export const PaymentProperty = ({ charge }: any) => {
  const isCard =
    isPaymentMethodTypeCard(charge) ||
    isPaymentMethodTypeGooglePay(charge) ||
    isPaymentMethodTypeApplePay(charge) ||
    isPaymentMethodTypeLink(charge);

  return (
    <>
      {isPaymentMethodTypePix(charge) && (
        <div className="flex flex-row gap-x-1">
          <PixProperty />
        </div>
      )}
      {isCard && (
        <div className="flex flex-row">
          <CardProperty charge={charge} creditCardIconClassName="!w-5" />
        </div>
      )}
      {isPaymentMethodTypeNull(charge) && <div>-</div>}
    </>
  );
};
