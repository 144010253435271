import { getStatPercentage } from "@circle-react/components/SettingsApp/Emails/components/helper";
import { toLocaleString } from "@circle-react/helpers/number";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface StatsPercentageCardProps {
  label: string;
  value: number | null;
  total: number | null;
  tooltipText: string | JSX.Element;
}

export const StatsPercentageCard = ({
  label,
  value,
  total,
  tooltipText,
}: StatsPercentageCardProps) => (
  <div className="flex flex-col gap-y-3 rounded-lg border px-6 py-5">
    <div className="flex items-center gap-x-2">
      <div>
        <Typography.LabelSm weight="semibold" color="text-default">
          {label}
        </Typography.LabelSm>
        <Typography.LabelSm weight="semibold" color="text-very-dark">
          {value ? toLocaleString(value) : 0}
        </Typography.LabelSm>
      </div>
      {tooltipText && (
        <TippyV2
          className="text-default flex items-center"
          content={tooltipText}
          interactive
          allowHTML
        >
          <Icon type="16-info" size={16} useWithStrokeCurrentColor />
        </TippyV2>
      )}
    </div>
    <Typography.TitleMd weight="font-bold" color="text-very-dark">
      {getStatPercentage({ total, value })}
    </Typography.TitleMd>
  </div>
);
