import { t } from "i18n-js";
import { isFunction } from "lodash";
import { usePaywallFilterList } from "@circle-react/hooks/paywalls/usePaywallFilterList";
import { paywallTypes } from "@circle-react/types";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import type { CheckboxFilterParam } from "@circle-react-shared/Filter/FilterTypes/CheckboxFilterV2";
import { Loader } from "@circle-react-uikit/Loader";

const localeNamespace = "members_directory.header.search.filters.paywalls";

interface PaywallsFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value: CheckboxFilterParam) => void;
  param: CheckboxFilterParam;
}

export const PaywallsFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: PaywallsFilterProps) => {
  const { paywalls, isPaywallsLoading } = usePaywallFilterList("paywalls", {
    type: [paywallTypes.space],
  });

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={paywalls} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {isPaywallsLoading ? (
        <Loader />
      ) : (
        <SingleSelectFilter
          options={paywalls}
          legend={t([localeNamespace, "singular_legend"])}
          param={param}
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
        />
      )}
    </Filter>
  );
};
