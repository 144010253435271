import { useEffect, useMemo } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { flatten, isFunction } from "lodash";
import { useInfiniteQuery } from "react-query";
import { usePunditUserContext } from "@circle-react/contexts";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { MemberTagsVisibleInMemberList } from "@circle-react/helpers/memberTagsHelper";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import type { CheckboxFilterParam } from "@circle-react-shared/Filter/FilterTypes/CheckboxFilterV2";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";

const localeNamespace = "members_directory.header.search.filters.tags";
const marginTopClassName = "mt-[3px]";

interface MemberTagsFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: CheckboxFilterParam) => void;
  param: CheckboxFilterParam;
}

export const MemberTagsFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: MemberTagsFilterProps) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  const fetchPage = ({ pageParam = 1 }) =>
    reactQueryGet(
      internalApi.memberTags.index({
        params: {
          page: pageParam,
        },
      }),
    );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["member-tags"],
    fetchPage,
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    }
  }, [data]);

  const memberTags = useMemo(() => {
    const flatMemberRecords = flatten(data?.pages.map(page => page.records));
    const formatTags = (tag: any) => ({
      label: tag.name,
      richLabel: (
        <span className="flex gap-1.5">
          {tag.custom_emoji_url ? (
            <img
              loading="lazy"
              className={classNames("block h-4", marginTopClassName)}
              src={tag.custom_emoji_url}
              alt={tag.name}
            />
          ) : (
            <span>{tag.emoji}</span>
          )}
          <span>{tag.name}</span>
        </span>
      ),
      value: tag.id.toString(),
    });

    if (isAdmin) {
      return flatMemberRecords.map(formatTags);
    }

    return MemberTagsVisibleInMemberList({
      memberTags: flatMemberRecords,
    }).map(formatTags);
  }, [data, isAdmin]);

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={memberTags} param={param} />
      )}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      isInitialOpen={isInitialOpen}
      shouldShowRemoveButton
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!memberTags.length ? (
            <div className="flex h-28 items-center justify-center p-4">
              <Typography.LabelSm>
                {t([localeNamespace, "no_tags"])}
              </Typography.LabelSm>
            </div>
          ) : (
            <SingleSelectFilter
              options={memberTags}
              legend={t([localeNamespace, "singular_legend"])}
              param={param}
              hasSearch
              searchProps={{
                placeholder: t([localeNamespace, "search_placeholder"]),
              }}
            />
          )}
        </>
      )}
    </Filter>
  );
};
