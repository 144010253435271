import { useFormContext } from "react-hook-form";
import { BROADCAST_PERSONALIZATION_VARIABLES } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { SUBJECT_MAX_LENGTH } from "@circle-react/components/SettingsApp/Emails/components/Builder/constants";
import { t } from "@circle-react/custom_i18n";
import { InputWithCounter } from "@circle-react-shared/InputWithCounter";
import { Form } from "@circle-react-shared/uikit/Form";
import { InsertVariableButton } from "@circle-react-shared/uikit/InsertVariableButton";
import { useInsertVariableForInput } from "@circle-react-shared/uikit/InsertVariableButton/useInsertVariableForInput";

const localeNamespace = "settings.emails.builder";

interface SubjectFieldProps {
  fieldName: string;
  onChange?: () => void;
}

export function SubjectField({ fieldName, onChange }: SubjectFieldProps) {
  const { watch } = useFormContext();
  const subjectValue = watch(fieldName);
  const { inputRef, setChangeHandler } = useInsertVariableForInput({
    value: subjectValue,
  });

  return (
    <Form.Item
      isFirstChild
      hideBorder
      name={fieldName}
      hideLabel
      hideDescription
    >
      <InputWithCounter
        label={t([localeNamespace, "subject_line"])}
        maxCount={SUBJECT_MAX_LENGTH}
        tooltipText={t([localeNamespace, "subject_description"])}
        onChange={onChange}
        ref={inputRef}
        className="form-control !pr-12"
      />
      <InsertVariableButton
        className="text-lightest dark:text-default"
        wrapperClassName="absolute right-4 top-9"
        optionsClassName="z-10 !w-60 right-2"
        variables={BROADCAST_PERSONALIZATION_VARIABLES}
        inputRef={inputRef}
        setInputChangeHandler={setChangeHandler}
      />
    </Form.Item>
  );
}
