import { t } from "i18n-js";
import invariant from "tiny-invariant";
import { Typography } from "@circle-react-uikit/Typography";

const SliderLabelType = {
  min: t("min"),
  max: t("max"),
};

export function SliderLabel({
  type,
  value,
}: {
  type: "min" | "max";
  value: string | number;
}) {
  const sliderLabel = SliderLabelType[type];
  invariant(sliderLabel, `Invalid slider label type: ${type}`);

  return (
    <div className="flex flex-col gap-y-1">
      <Typography.LabelXs>{sliderLabel}</Typography.LabelXs>
      <Typography.LabelLg weight="semibold">{value}</Typography.LabelLg>
    </div>
  );
}
