import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const ChargeInvoiceIdProperty = ({ charge }: any) =>
  charge.invoice_processor_id && (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.invoice_processor_id`)}
    >
      <Typography.LabelSm>{charge.invoice_processor_id}</Typography.LabelSm>
    </PropertyLine>
  );
