import { t } from "i18n-js";
import { isFunction } from "lodash";
import {
  Filter,
  RadioFilterV2 as RadioFilter,
} from "@circle-react-shared/Filter";
import type {
  RadioFilterOptions,
  RadioFilterParam,
} from "@circle-react-shared/Filter/FilterTypes/RadioFilterV2";

const localeNamespace = "members_directory.header.search.filters";

const options: RadioFilterOptions = [
  { label: t([localeNamespace, "subscribed"]), value: "true" },
  { label: t([localeNamespace, "not_subscribed"]), value: "false" },
];

const renderSelectedOptionLabel = ({
  selectedText,
}: {
  selectedText: string;
}) => {
  const selectedOption = options.find(option => option.value === selectedText);
  return selectedOption ? selectedOption.label : "";
};

interface MemberFilterOptions {
  isInitialOpen?: boolean;
  onApply?: (value: RadioFilterParam) => void;
  param: RadioFilterParam;
}

export const EmailMarketingFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: MemberFilterOptions) => (
  <Filter
    chip={t([localeNamespace, "email_marketing"])}
    title={t([localeNamespace, "email_marketing"])}
    selectedText={param.value}
    renderSelectedText={renderSelectedOptionLabel}
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <RadioFilter
      param={param}
      options={options}
      legend={t([localeNamespace, "email_marketing_legend"])}
    />
  </Filter>
);
