import { t } from "i18n-js";
import invariant from "tiny-invariant";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export interface DaysFilterParam extends BaseFilter {
  filter_type: string;
  value: number;
}

export interface DaysAgoFormOption {
  label: string;
  value: string;
}

const isDaysFilterParam = (
  options: DaysAgoFormOption[],
  param: BaseFilter,
): param is DaysFilterParam =>
  "filter_type" in param &&
  options.some(option => option.value === param.filter_type);

const localeNamespace = "members_directory.header.search.filters";

export const renderSelectedOption =
  (options: DaysAgoFormOption[]) => (selectedParam: BaseFilter) => {
    if (!isDaysFilterParam(options, selectedParam)) {
      return null;
    }
    const operationOption = options.find(
      option => option.value === selectedParam.filter_type,
    );
    invariant(
      operationOption,
      "Could not find option from the related options",
    );

    const daysAgoLabel = t([
      localeNamespace,
      "days_ago",
      operationOption.value,
    ]);
    const daysCountLabel = t([localeNamespace, "days_ago.day"], {
      count: +selectedParam.value,
    });

    return `${daysAgoLabel} ${daysCountLabel}`;
  };
