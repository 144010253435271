import { t } from "i18n-js";
import { isRefundedOrPartialRefunded } from "@circle-react/helpers/communityMemberChargeHelpers";
import { PaywallChargeBadge } from "@circle-react-shared/Paywalls/PaywallChargeBadge";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const ChargeStatusProperty = ({ charge }: any) =>
  isRefundedOrPartialRefunded(charge) && (
    <PropertyLine title={t(`${localeStringRoot}.details_labels.status`)}>
      <PaywallChargeBadge status={charge?.display_status} />
    </PropertyLine>
  );
