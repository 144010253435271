import type { MouseEventHandler } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";

export const ClearButton = ({
  isVisible = true,
  onClick,
}: {
  isVisible: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button
    type="submit"
    className={classNames("text-light hover:text-darkest flex text-sm", {
      invisible: !isVisible,
    })}
    disabled={!isVisible}
    onClick={onClick}
  >
    <Icon type="20-close-sm" size={20} useWithFillCurrentColor aria-hidden />
    <p>{t("filters.clear_filters")}</p>
  </button>
);
