import { t } from "i18n-js";
import { useMutation } from "react-query";
import { paywallSubscriptionsApi } from "@/react/api/paywallSubscriptionsApi";
import { useToast } from "@circle-react-uikit/ToastV2";

const { resumeSubscription } = paywallSubscriptionsApi;

export const useResumePaywallSubscriptionApi = ({ callback }) => {
  const { success, error } = useToast();
  const defaultErrorMessage = t(
    "settings.paywalls_admin_dashboard.subscriptions.alerts.errors.action_failed",
  );

  const resumeMutation = useMutation(
    subscriptionProcessorId =>
      resumeSubscription({
        subscriptionProcessorId,
      }),
    {
      onSuccess: () => {
        callback?.onSuccess();
        success(
          t(
            "settings.paywalls_admin_dashboard.subscriptions.alerts.success.resume_subscription",
          ),
        );
      },
      onError: updateError => {
        console.error(updateError?.errorDetails);
        error(updateError?.errorDetails ?? defaultErrorMessage, {
          duration: "long",
        });
      },
    },
  );

  return {
    resumeMutation,
  };
};
