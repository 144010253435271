import { useMemo } from "react";
import { t } from "i18n-js";
import { FILTERS } from "@circle-react/components/Paywalls/Admin/Filters/useFilters";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const FilterAppliedCallout = () => {
  const queryParams = useRouterQueryParams();

  const filtersByQueryParams = useMemo(
    () =>
      Object.keys(FILTERS).reduce<any>((acc, filter) => {
        FILTERS[filter].filterKeys.forEach((filterKey: any) => {
          acc[filterKey] = FILTERS[filter].label;
        });

        return acc;
      }, {}),
    [],
  );

  const usedFilters = Object.keys(
    Object.keys(queryParams).reduce<any>((acc, element) => {
      if (filtersByQueryParams[element]) {
        const filterName = filtersByQueryParams[element];
        acc[filterName] = 1 + (acc[filterName] ?? 0);
      }

      return acc;
    }, {}),
  );

  const filterAppliedString = t(
    "export_paywall_csv.modal.filter_applied_callout",
    {
      filter: usedFilters[0],
      count: usedFilters.length,
    },
  );

  return (
    <div className="bg-tertiary mt-4 flex gap-x-2 rounded-md p-4">
      <div>
        <Icon type="info" className="!w-6" />
      </div>
      <div className="self-start">
        <Typography.LabelSm>{filterAppliedString}</Typography.LabelSm>
      </div>
    </div>
  );
};
