import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface FiltersRowProps {
  className?: string;
  children: ReactNode;
}

export const FiltersRow = ({
  className,
  children,
  ...rest
}: FiltersRowProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames(
        "bg-primary border-primary flex items-center justify-between gap-x-1 border-b px-6 py-3",
        className,
        { "lg:px-9": isV3Enabled },
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
