import { t } from "i18n-js";
import { SpaceGroupListView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceGroupList/SpaceGroupListView";
import { SpaceGroupView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceGroupList/SpaceGroupView";
import { SpaceListView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceListView";
import { SpaceView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceView";
import { GoLivePoweredBy } from "@circle-react/components/Spaces/SidebarV2/GoLivePoweredBy";
import { LinksWrapper } from "@circle-react/components/Spaces/SidebarV2/LinksWrapper";
import { LogoTopSectionView } from "@circle-react/components/Spaces/SidebarV2/LogoTopSection/LogoTopSectionView";
import { TopSectionContainer } from "@circle-react/components/Spaces/SidebarV2/LogoTopSection/TopSectionContainer";
import { PlatformMenuView } from "@circle-react/components/Spaces/SidebarV2/PlatformMenu/PlatformMenuView";
import { PlatformMenuV3 } from "@circle-react/components/Spaces/SidebarV2/PlatformMenuV3";
import { ProfileWrapper } from "@circle-react/components/Spaces/SidebarV2/ProfileWrapper";
import { SidebarV2Wrapper } from "@circle-react/components/Spaces/SidebarV2/SidebarV2Wrapper";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import {
  coursesSpaceGroup,
  coursesSpaces,
  getStartedSpaceGroup,
  getStartedSpaces,
} from "./constants";

const renderSpace = (space: any, shouldShowNotifications: any) => (
  <SpaceView
    key={space.id}
    space={space}
    buttonLink=""
    spaceNotificationDetail={{
      unread_content_count: shouldShowNotifications ? 1 : 0,
      unread_notifications_count: 0,
    }}
  />
);

export interface SidebarPreviewProps {
  communityName?: string;
  logoType?: "logo" | "icon";
  logoUrl?: string;
}

export const SidebarPreview = ({
  communityName = "My Community",
  logoType = "icon",
  logoUrl,
}: SidebarPreviewProps) => {
  const { isV3Enabled } = useIsV3();
  return (
    <SidebarV2Wrapper>
      {!isV3Enabled && (
        <TopSectionContainer
          hasBorder
          hasPadding={false}
          className="pointer-events-none"
        >
          <LogoTopSectionView
            communityName={communityName}
            logoType={logoType}
            logoUrl={logoUrl}
            showToggleTheme
          />
        </TopSectionContainer>
      )}
      <LinksWrapper>
        {!isV3Enabled && (
          <PlatformMenuView
            links={[]}
            disabled
            hasCurrentCommunityMember
            isHomePageEnabled
            isMessagingEnabled
            notificationBadgeText={t("live_streams.now_live.one")}
            shouldShowActiveHomeLink
            shouldShowMemberDirectory
            shouldShowOnboarding={false}
            shouldShowSpaceDirectory
          />
        )}
        {isV3Enabled && <PlatformMenuV3 isDisabled isFeedLinkActive />}
        <SpaceGroupListView>
          <SpaceGroupView
            id="get-started"
            listId="get-started"
            spaceGroup={getStartedSpaceGroup}
            canManage={false}
            shouldDisplayOptions={false}
          >
            <SpaceListView>
              {getStartedSpaces.map(space => renderSpace(space, true))}
            </SpaceListView>
          </SpaceGroupView>
          <SpaceGroupView
            id="courses"
            listId="courses"
            spaceGroup={coursesSpaceGroup}
            canManage={false}
            shouldDisplayOptions={false}
          >
            <SpaceListView>
              {coursesSpaces.map(space => renderSpace(space, false))}
            </SpaceListView>
          </SpaceGroupView>
        </SpaceGroupListView>
      </LinksWrapper>
      <ProfileWrapper>
        <GoLivePoweredBy isPreviewMode />
      </ProfileWrapper>
    </SidebarV2Wrapper>
  );
};
