import type { Space } from "@circle-react/types/Space";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";

export const getStartedSpaceGroup: SpaceGroup = {
  id: 1,
  name: "Get Started",
  slug: "get-started",
  automatically_add_members_to_new_spaces: false,
  active_members_count: 0,
  spaces: [],
};

export const getStartedSpaces: Partial<Space>[] = [
  {
    id: 1,
    name: "Start Here",
    emoji: "🏠",
    is_private: false,
  },
  {
    id: 2,
    name: "Say Hello",
    emoji: "👋",
    is_private: false,
  },
  {
    id: 3,
    name: "Resources",
    emoji: "📖",
    is_private: false,
  },
];

export const coursesSpaceGroup: SpaceGroup = {
  id: 2,
  name: "Courses",
  slug: "courses-space",
  automatically_add_members_to_new_spaces: false,
  active_members_count: 0,
  spaces: [],
};

export const coursesSpaces: Partial<Space>[] = [
  {
    id: 1,
    name: "Disscussions",
    emoji: "💬",
    is_private: false,
  },
  {
    id: 2,
    name: "Welcome",
    emoji: "🤝",
    is_private: false,
  },
  {
    id: 3,
    name: "Module 1",
    emoji: "1️⃣",
    is_private: false,
  },
  {
    id: 4,
    name: "Module 2",
    emoji: "2️⃣",
    is_private: false,
  },
  {
    id: 5,
    name: "Module 3",
    emoji: "3️⃣",
    is_private: false,
  },
];
