import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import { usePunditUserContext } from "@/react/contexts";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const AnalyticsLinks = () => {
  const location = useLocation();
  const { currentCommunitySettings } = usePunditUserContext();
  const links: Link[] = [
    {
      label: t("settings_nav_v3.analytics_v2.overview"),
      path: settingsNavPath.analytics_v2.index,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.members"),
      path: settingsNavPath.analytics_v2.members,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.spaces"),
      path: settingsNavPath.analytics_v2.spaces,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.post_comments"),
      path: settingsNavPath.analytics_v2.post_comments,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.messages"),
      path: settingsNavPath.analytics_v2.messages,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.devices"),
      path: settingsNavPath.analytics_v2.devices,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.events"),
      path: settingsNavPath.analytics_v2.events,
      exact: true,
    },
    {
      label: t("settings_nav_v3.analytics_v2.payments"),
      path: settingsNavPath.analytics_v2.payments,
      exact: true,
    },
  ];

  if (
    currentCommunitySettings &&
    currentCommunitySettings.courses_analytics_enabled
  ) {
    links.push({
      label: t("settings_nav_v3.analytics_v2.courses"),
      path: settingsNavPath.analytics_v2.courses,
      exact: true,
    });
  }

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.analytics_v2.label")}>
        {links.map(link => (
          <SubNavLinkItem
            key={link.label}
            label={link.label}
            path={{
              pathname: link.path,
              search: location.search,
            }}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
