import { t } from "i18n-js";
import { useBuilderBlockContext } from "@circle-react/components/SettingsApp/Emails/components/Builder/BuilderBlock/BuilderBlockContext";
import { useSendTestEmail } from "@circle-react/components/SettingsApp/Emails/hooks/useSendTestEmail";
import { Button } from "@circle-react-shared/uikit/Button";

export const PreviewActions = () => {
  const { handleTestEmail } = useSendTestEmail();
  const { filledStatus } = useBuilderBlockContext();
  const isSendTestDisabled = !filledStatus.get("email_content");

  return (
    <div className="bg-tertiary flex justify-center gap-x-2 border-t py-3">
      <Button
        variant="secondary"
        disabled={isSendTestDisabled}
        onClick={handleTestEmail}
        type="button"
        className="!border-dark"
      >
        {t("settings.emails.builder.send_a_test")}
      </Button>
      {/* TODO: Implement this after we have the preview functionality */}
      {/* <Button variant="secondary" disabled={isPreviewDisabled}>
        {t("settings.emails.broadcasts.preview")}
      </Button> */}
    </div>
  );
};
