import { t } from "i18n-js";
import { isFunction } from "lodash";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import type { SingleSelectFilterOption } from "@circle-react-shared/Filter/FilterTypes/SingleSelectFilter/SelectFilterOption";
import type { BaseFilter } from "../types";

const localeNamespace =
  "members_directory.header.search.filters.invitation_status";

const invitationStatusOptions: SingleSelectFilterOption[] = [
  { label: t("invitation_status_column.invited"), value: "invited" },
  {
    label: t("invitation_status_column.invite_opened"),
    value: "invite_opened",
  },
  {
    label: t("invitation_status_column.account_created"),
    value: "account_created",
  },
  {
    label: t("invitation_status_column.profile_completed"),
    value: "profile_completed",
  },
];

interface InvitationStatusFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter) => void;
  param: BaseFilter;
}

export const InvitationStatusFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: InvitationStatusFilterProps) => (
  <Filter
    chip={t([localeNamespace, "title"])}
    title={t([localeNamespace, "title"])}
    selectedText={param.value}
    renderSelectedText={() => (
      <SingleSelectFilterSelectedText
        items={invitationStatusOptions}
        param={param}
      />
    )}
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <SingleSelectFilter
      options={invitationStatusOptions}
      legend={t([localeNamespace, "legend"])}
      param={param}
    />
  </Filter>
);
