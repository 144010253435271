import { useMemo } from "react";
import { t } from "i18n-js";
import { isNil } from "lodash";
import { Controller } from "react-hook-form";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../../Filter";
import { BaseFilterFields } from "../BaseFilterFields";
import { SliderWithLabels } from "./SliderWithLabels";

export interface SliderFilterParam extends BaseFilter {
  gte: number;
  lte: number;
}

interface SliderFilterFormValues extends BaseFilter {
  slider: [number, number];
}

export const isSliderFilterParam = (
  param?: BaseFilter,
): param is SliderFilterParam => {
  if (isNil(param)) return false;
  return "gte" in param && "lte" in param;
};

interface SliderFilterProps {
  param: BaseFilter;
}

export const SliderFilter = ({ param }: SliderFilterProps) => {
  const { onApply } = useFilterContext();
  const primaryButtonVariant = useButtonVariant();

  const defaultValue: any = useMemo(
    () => ({
      ...param,
      slider: ["gte" in param ? param.gte : 0, "lte" in param ? param.lte : 10],
    }),
    [param],
  );

  const handleApply = (submitData: SliderFilterFormValues, { reset }: any) => {
    const [gte, lte] = submitData.slider;
    onApply({
      ...param,
      gte,
      lte,
    });
    reset();
  };

  return (
    <Form defaultValues={defaultValue} onSubmit={handleApply}>
      <div className="flex min-w-[18rem] flex-col gap-y-4 p-4">
        <BaseFilterFields />
        <Controller
          name="slider"
          render={({ field: { onChange, onBlur, value } }) => {
            const getSelectedValue = () => {
              if (value == null) {
                /* Errors out when sending in `null`. Replacing that with `undefined` */
                return undefined;
              }
              return value;
            };
            return (
              <SliderWithLabels
                value={getSelectedValue()}
                onChange={onChange}
                onBlur={onBlur}
              />
            );
          }}
        />
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </div>
    </Form>
  );
};
