import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { NavLinkWrapper } from "./NavLinkWrapper";

export interface LinkItemProps {
  label: string;
  path: string;
  icon: IconType;
  pathKey?: string;
}

export const LinkItem = ({ label, path, icon, pathKey }: LinkItemProps) => {
  const match = useRouteMatch({
    path: path,
    exact: true,
  });
  const isRouteMatch = Boolean(match);

  return (
    <TippyV2
      interactive={false}
      key={label}
      content={label}
      placement="right"
      arrow={false}
    >
      <NavLinkWrapper path={path} pathKey={pathKey}>
        <Icon
          type={icon}
          className={classNames({
            "text-default": !isRouteMatch,
            "text-selector-active": isRouteMatch,
          })}
          size={20}
        />
      </NavLinkWrapper>
    </TippyV2>
  );
};
