import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Icon } from "@circle-react-shared/Icon";
import { useCreatePaywallSubscriptionFormContext } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/CreatePaywallSubscriptionFormContextProvider";
import { useCouponForm } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/hooks/useCouponForm";
import { Form } from "@circle-react-shared/uikit/Form";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { popperSameWidthModifier } from "@circle-react-uikit/Dropdown/utils";
import { useModalData } from "@circle-react-uikit/ModalV2";

const localeRoot = "paywalls.apply_coupon_modal";

export const CouponForm = ({ paywallId }: any) => {
  const { contentPortalElement } = useModalData();
  const { couponCodeOptions, paywallCouponsListQuery } = useCouponForm({
    paywallId,
  });
  const { watch, setValue } = useFormContext();
  const { couponsIndex } = useCreatePaywallSubscriptionFormContext();

  const removeCoupon = () => {
    setValue("coupon_code", null);
  };

  const couponCode = watch("coupon_code");
  if (paywallCouponsListQuery.isLoading) {
    return <Loader />;
  }

  if (couponCode) {
    const couponSelected = (couponsIndex ?? {})[couponCode];

    return (
      <div className="flex flex-col gap-y-1">
        <Typography.LabelSm weight="semibold">
          {t([localeRoot, "coupon"])}
        </Typography.LabelSm>
        <div className="flex">
          <div className="bg-tertiary rounded-lg p-3">
            <Typography.LabelSm weight="medium">
              {`${couponSelected.name} (${couponSelected.terms})`}
            </Typography.LabelSm>
            <Icon
              width="20"
              height="20"
              viewBox="0 0 16 16"
              className="text-light mb-[1.5px] ml-2.5 cursor-pointer"
              type="16-close"
              onClick={removeCoupon}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-1">
      <Typography.LabelSm weight="semibold">
        {t([localeRoot, "coupon_code"])}
      </Typography.LabelSm>
      <Form.TextSelect
        name="coupon_code"
        options={couponCodeOptions}
        label={null}
        appendTo={contentPortalElement}
        popperOptions={{
          modifiers: [{ ...popperSameWidthModifier, phase: "afterWrite" }],
        }}
      />
    </div>
  );
};
