import { useRef } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { useFormContext } from "react-hook-form";
import { useUsageContext } from "@circle-react/providers";
import { Form } from "@circle-react-uikit/Form";

interface InputItemProps {
  label: string;
  value: string;
  placeholder?: string;
}

export function InputItem({ label, value, placeholder }: InputItemProps) {
  const inputIdRef = useRef(uniqueId());
  const { current: inputId } = inputIdRef;

  const { section } = useUsageContext();
  const isSectionAdmin = section === "admin";

  const comparatorRadioName = "filter_type";
  const { watch, register } = useFormContext();
  const comparatorSelectedValue = watch(comparatorRadioName);

  return (
    <div className="flex px-4 py-3">
      <div className="grid w-full grid-cols-[min-content,_1fr] grid-rows-[min-content,_1fr] gap-x-4">
        <input
          type="radio"
          value={value}
          id={inputId}
          className={classNames(
            "peer row-span-2 mt-1",
            isSectionAdmin ? "text-default" : "text-darkest",
          )}
          {...register(comparatorRadioName)}
        />
        <label htmlFor={inputId} className="mb-0 text-base font-normal">
          {label}
        </label>
        {comparatorSelectedValue === value && (
          <div className="mt-3 w-full">
            <Form.Input name="value" autoFocus placeholder={placeholder} />
          </div>
        )}
      </div>
    </div>
  );
}
