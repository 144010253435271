import { t } from "i18n-js";
import { BigLoader } from "@circle-react-shared/BigLoader";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useApplyCouponContext } from "../ApplyCouponContextProvider";

const localeRoot = "paywalls.apply_coupon_modal";
const localeRemovingCoupon = "paywalls.apply_coupon_modal.removing_coupon_step";
const localeApplyingCoupon = "paywalls.apply_coupon_modal.applying_coupon_step";

const stepProperties = {
  step: "ApplyingCouponStep",
  nextStep: null,
  title: null,
  shouldHiddenCloseButton: true,
  shouldHiddenBackButton: true,
};

const Component = () => {
  const {
    getCurrentPage,
    applyCouponMutation,
    onClose,
    shouldRemoveCoupon,
    removeCouponMutation,
  } = useApplyCouponContext();

  const mutation = shouldRemoveCoupon
    ? removeCouponMutation
    : applyCouponMutation;
  const selectedLocale = shouldRemoveCoupon
    ? localeRemovingCoupon
    : localeApplyingCoupon;

  if (getCurrentPage().step !== stepProperties.step) {
    return null;
  }

  return (
    <>
      {mutation.isLoading && (
        <>
          <Modal.Body>
            <BigLoader
              title={t([localeRoot, "loading.title"])}
              subtitle={t([localeRoot, "loading.subtitle"])}
              variant="loading"
            />
          </Modal.Body>
          <Modal.Footer>{null}</Modal.Footer>
        </>
      )}
      {mutation.isSuccess && (
        <>
          <Modal.Body>
            <BigLoader
              title={t([selectedLocale, "success.title"])}
              variant="success"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button full large variant="circle" type="button" onClick={onClose}>
              {t(["close"])}
            </Button>
          </Modal.Footer>
        </>
      )}
      {mutation.isError && (
        <>
          <Modal.Body>
            <BigLoader
              title={t([selectedLocale, "failed.title"])}
              subtitle={t([selectedLocale, "failed.subtitle"])}
              variant="error"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button full large variant="circle" type="button" onClick={onClose}>
              {t(["close"])}
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  );
};

export const ApplyingCouponStep = Object.assign(Component, {
  stepProperties,
});
