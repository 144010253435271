import type { PaywallsAdminExportsCreate } from "@circle-react/api/paywallsExportCsvApi";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import {
  allFilterTranslations,
  normalizeFilters,
} from "@circle-react/components/Paywalls/Admin/Filters/useFilters";
import { usePunditUserContext } from "@circle-react/contexts";
import { usePaywallExportCsvApi } from "@circle-react/hooks/paywalls/usePaywallExportCsvApi";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { useConfirmExportModal } from "../ConfirmExportModal";

export const useExportCsvModalHooks = ({ relation }: any) => {
  const queryParams = useRouterQueryParams();
  const { createCsvExportMutation } = usePaywallExportCsvApi();
  const confirmExportModal = useConfirmExportModal();
  const {
    currentCommunityMember: { email },
  } = usePunditUserContext();

  const modal = useModal();
  const onClose = () => {
    void modal.remove();
  };

  const filterTranslation: any = allFilterTranslations[relation];

  const { mutate, isSuccess, isLoading } = createCsvExportMutation;

  const onSubmit = (fields: PaywallsAdminExportsCreate) => {
    mutate({
      ...fields,
      relation,
      filters: normalizeFilters(queryParams, filterTranslation),
    });

    void modal.remove();
    void confirmExportModal.show({ email });
  };

  return {
    modal,
    onClose,
    onSubmit,
    formId: `export-csv-modal-${relation}`,
    isSuccess,
    isLoading,
  };
};
