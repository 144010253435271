import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface BodyWrapperProps {
  children: ReactNode;
  className?: string;
  hasCommunitySwitcher?: boolean;
}

export const BodyWrapper = ({
  children,
  className = "",
  hasCommunitySwitcher = true,
}: BodyWrapperProps) => {
  const { isV3Enabled } = useIsV3();
  return (
    <div
      className={classNames(
        "has-bottom-banner is-signed-in view-homepage is-standard-layout-v2 react-standard-layout bg-secondary h-full w-full",
        { "has-community-switcher": hasCommunitySwitcher && !isV3Enabled },
        className,
      )}
    >
      {children}
    </div>
  );
};
