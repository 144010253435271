import { usePunditUserContext } from "@circle-react/contexts";
import { hasRevenueCatPaymentProcessor } from "@circle-react/helpers/paymentProcessorHelpers";
import { possibleColumnsToRender } from "../constants";

export const useDefaultColumnsToRender = () => {
  const {
    currentCommunity: { payment_processors: currentCommunityPaymentProcessors },
  } = usePunditUserContext();

  const platformColumnToRender = hasRevenueCatPaymentProcessor(
    currentCommunityPaymentProcessors,
  )
    ? [possibleColumnsToRender.platform]
    : [];

  const defaultColumnsToRender = [
    possibleColumnsToRender.customer,
    possibleColumnsToRender.status,
    possibleColumnsToRender.paywall,
    ...platformColumnToRender,
    possibleColumnsToRender.chargesQuantity,
    possibleColumnsToRender.term,
    possibleColumnsToRender.startDate,
    possibleColumnsToRender.renewalDate,
    possibleColumnsToRender.actionMenu,
  ];

  return {
    defaultColumnsToRender,
  };
};
