import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { FiltersList } from "@circle-react-shared/Filter/FiltersList";

/**
 * Expanded suite of radio buttons that look like Filter buttons
 */
export const FilterExpand = ({
  label,
  selectedText,
  onChange,
  options = [],
}) => (
  <RadioGroup value={selectedText} onChange={onChange} as="li">
    <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
    <FiltersList>
      {options.map(option => (
        <RadioGroup.Option value={option.value} key={option.label} as="li">
          {({ checked }) => (
            <span
              className={classNames(
                "flex cursor-pointer items-center rounded-3xl border px-3 py-1 text-xs",
                checked
                  ? "text-dark border-secondary"
                  : "text-default border-primary",
              )}
            >
              {option.label}
            </span>
          )}
        </RadioGroup.Option>
      ))}
    </FiltersList>
  </RadioGroup>
);

FilterExpand.propTypes = {
  label: PropTypes.string.isRequired,
  selectedText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};
