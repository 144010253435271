import classNames from "classnames";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import type { CoverImageInputProps } from "@circle-react-uikit/CoverImageInput";
import { CoverImageInput } from "@circle-react-uikit/CoverImageInput";
import { Typography } from "@circle-react-uikit/Typography";
import { ImageInputRender } from "./ImageInputRender";
import { SelectImageButton } from "./SelectImageButton";

export interface ImageUploadInputProps extends CoverImageInputProps {
  className?: string;
  description?: string;
  isHorizontal?: boolean;
  label?: string;
  previewVariant?: "default" | "cover";
  tooltip?: string;
}

export const ImageUploadInput = ({
  className = "",
  description = "",
  isHorizontal = false,
  label = "",
  previewVariant = "default",
  tooltip = "",
  ...rest
}: ImageUploadInputProps) => (
  <div
    className={classNames(
      "flex flex-col",
      {
        "gap-1 md:flex-row md:gap-4": isHorizontal,
      },
      className,
    )}
  >
    <div
      className={classNames("text-dark mb-2 flex", {
        "flex-col": description,
        "items-center": !description,
        "w-full": isHorizontal,
      })}
    >
      <div>
        <Typography.LabelSm weight="semibold">{label}</Typography.LabelSm>
        {tooltip && (
          <TippyV2 content={tooltip}>
            <Icon
              type="16-question-mark"
              size={16}
              className="ml-1 !h-4 !w-4"
            />
          </TippyV2>
        )}
      </div>
      {description && (
        <div>
          <Typography.LabelSm>{description}</Typography.LabelSm>
        </div>
      )}
    </div>
    <div className="w-full">
      <CoverImageInput
        showAddCoverBtn
        customAddCoverBtn={
          <SelectImageButton
            text={t("customize_theme.logo_tab.select_image")}
          />
        }
        renderer={props => (
          <ImageInputRender variant={previewVariant} {...props} />
        )}
        changeImageText={t("replace")}
        hideUnsplash
        hideEmbedUrl
        {...rest}
      />
    </div>
  </div>
);
