import { PropTypes } from "prop-types";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useFeatureDetailsModal } from "./FeatureDetailsModal";

export const UnlockPlanFeature = ({ PlanFeatureName }) => {
  const featureDetailsModal = useFeatureDetailsModal();

  const showFeatureDetailsModal = e => {
    e.preventDefault();
    return featureDetailsModal.show({
      PlanFeatureName,
    });
  };

  return (
    <div className="my-8 rounded-md border border-l-8 border-amber-500 p-5">
      <Typography.LabelLg weight="semibold">
        {t("plan_details.unlock_feature.upgrade_to_unlock")}
      </Typography.LabelLg>
      <div className="mt-2">
        <Typography.BodyMd color="text-gray-600">
          {t("plan_details.unlock_feature.get_feature", {
            feature: PlanFeatureName,
          })}
        </Typography.BodyMd>
      </div>

      <div className="mt-4">
        <Button onClick={showFeatureDetailsModal} variant="secondary-light">
          {t("plan_details.unlock_feature.actions.get_feature")}
        </Button>
      </div>
    </div>
  );
};

UnlockPlanFeature.propTypes = {
  PlanFeatureName: PropTypes.string.isRequired,
};
