import { t } from "i18n-js";
import {
  UpgradeFlow,
  useIsProfileFieldsEnabled,
  useShouldShowProfileFieldsUpgradeModal,
} from "@circle-react-shared/ProfileFields";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { AddFilterDropdown } from "../AddFilterDropdown";
import { FilterContainer } from "../AvailableFiltersList/FilterContainer";
import { useDynamicFilterContext } from "../DynamicFilterContext";
import type { AddFilterArgs } from "../useDynamicFilters";
import { FiltersList } from "./FiltersList";

interface AllFiltersListProps {
  label?: string;
}

export const AllFiltersList = ({ label }: AllFiltersListProps) => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const shouldShowProfileFieldsUpgradeModal =
    useShouldShowProfileFieldsUpgradeModal();
  const {
    platformFilters,
    profileFieldFilters = [],
    onAddFilter,
  } = useDynamicFilterContext();

  return (
    <AddFilterDropdown label={label}>
      <div className="flex max-h-96 flex-col gap-y-4 overflow-y-auto">
        <FiltersList
          title={t("filters.standard")}
          filters={platformFilters}
          onAddFilter={onAddFilter}
        />
        {shouldShowProfileFieldsUpgradeModal && (
          <FilterContainer title={t("profile_fields.upgrade_flow.title")}>
            <div className="mt-2 pl-4">
              <UpgradeFlow
                source={UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_FILTER_CHIP}
              />
            </div>
          </FilterContainer>
        )}
        {isProfileFieldsEnabled && (
          <FiltersList
            title={t("filters.custom")}
            filters={profileFieldFilters}
            onAddFilter={(params: AddFilterArgs) =>
              onAddFilter({ ...params, key: "profile_field" })
            }
          />
        )}
      </div>
    </AddFilterDropdown>
  );
};
