import { t } from "i18n-js";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { PaywallSubscriptionDetails } from "./PaywallSubscriptionDetails";

export const PaywallSubscriptionDetailsModal = (props: any) => {
  const modal = useModal();

  return (
    <ActionModal
      title={t(
        "settings.paywalls_admin_dashboard.subscriptions.subscription_details_modal.title",
      )}
      isOpen={modal.visible}
      onClose={modal.hide}
      actionVariant="danger"
    >
      <PaywallSubscriptionDetails {...props} />
    </ActionModal>
  );
};
