import { t } from "i18n-js";
import { NavLink } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";
import { BadgeV2 } from "@circle-react-uikit/BadgeV2";

type Path = NonNullable<NavLinkProps["to"]>;

export interface SubNavLinkItemProps {
  label: string;
  path: Path;
  isNewFeature?: boolean;
  isBetaFeature?: boolean;
  isDisabled?: boolean;
  badgeLabel?: string;
  exact?: boolean;
}

export const SubNavLinkItem = ({
  label,
  path,
  isNewFeature = false,
  isDisabled = false,
  isBetaFeature = false,
  exact = true,
  badgeLabel = "",
}: SubNavLinkItemProps) => {
  if (isDisabled) {
    return null;
  }

  return (
    <NavLink
      to={path}
      exact={exact}
      className="hover:bg-secondary text-default hover:text-selector-active focus:text-selector-active flex items-center justify-between rounded-md px-2 py-1.5 text-sm font-medium leading-5 transition-colors duration-150 ease-linear"
      activeClassName="bg-tertiary text-selector-active"
    >
      {label}
      {isNewFeature && <BadgeV2 label={t("new")} square />}
      {isBetaFeature && <BadgeV2 label={t("beta")} square />}
      {badgeLabel && <BadgeV2 label={badgeLabel} square inverted />}
    </NavLink>
  );
};
