import { t } from "i18n-js";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import type { ViewSwitcherLayoutOptions } from "./ViewSwitcher";

export const MEMBER_LAYOUT_OPTIONS: ViewSwitcherLayoutOptions[] = [
  {
    icon: "16-grid",
    label: t("views.cards"),
    value: VIEWS.CARDS,
  },
  {
    icon: "bullet-list-text",
    label: t("views.list"),
    value: VIEWS.LIST,
  },
];
