import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { LinkItem } from "./LinkItem";
import { useCollapsedSidebarLinksV2 } from "./useCollapsedSidebarLinksV2";
import { useCollapsedSidebarLinksV3 } from "./useCollapsedSidebarLinksV3";

export const CollapsedSidebar = () => {
  const settingsNavLinks = useCollapsedSidebarLinksV2();
  const settingsNavLinksV3 = useCollapsedSidebarLinksV3();
  const { isV3Enabled } = useIsV3();

  const links = isV3Enabled ? settingsNavLinksV3 : settingsNavLinks;

  return (
    <div className="bg-primary border-r-primary flex h-full w-16 flex-col items-center justify-start space-y-2 border-r p-3">
      {links.map(({ pathKey, label, path, icon, enabled }) => {
        if (!enabled) return null;
        return (
          <LinkItem
            key={label}
            pathKey={pathKey}
            label={label}
            path={path}
            icon={icon}
          />
        );
      })}
    </div>
  );
};
