import PropTypes from "prop-types";
import { t } from "i18n-js";
import { STATUS_PRESENTER } from "@circle-react/helpers/communityMemberChargeHelpers";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";

const badgeStatus = {
  [STATUS_PRESENTER.INCOMPLETE]: {
    label: t(
      "settings.paywalls_admin_dashboard.charges.badge_status.incomplete",
    ),
    variant: "default",
  },
  [STATUS_PRESENTER.PROCESSING]: {
    label: t(
      "settings.paywalls_admin_dashboard.charges.badge_status.processing",
    ),
    variant: "default",
  },
  [STATUS_PRESENTER.PAID]: {
    label: t("settings.paywalls_admin_dashboard.charges.badge_status.paid"),
    variant: "success",
  },
  [STATUS_PRESENTER.CANCELED]: {
    label: t("settings.paywalls_admin_dashboard.charges.badge_status.canceled"),
    variant: "default",
  },
  [STATUS_PRESENTER.PARTIAL_REFUND]: {
    label: t(
      "settings.paywalls_admin_dashboard.charges.badge_status.partial_refund",
    ),
    variant: "default",
  },
  [STATUS_PRESENTER.REFUNDED]: {
    label: t("settings.paywalls_admin_dashboard.charges.badge_status.refunded"),
    variant: "default",
  },
  [STATUS_PRESENTER.FAILED]: {
    label: t("settings.paywalls_admin_dashboard.charges.badge_status.failed"),
    variant: "danger",
  },
  unknown: {
    label: t("settings.paywalls_admin_dashboard.charges.badge_status.unknown"),
    variant: "default",
  },
};

export const PaywallChargeBadge = ({ status }) => {
  const badgePointer = badgeStatus[status] ?? badgeStatus["unknown"];

  return (
    <div>
      <StatusBadge variant={badgePointer.variant}>
        {badgePointer.label}
      </StatusBadge>
    </div>
  );
};

PaywallChargeBadge.propTypes = {
  status: PropTypes.string,
};
