import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const PaywallNameProperty = ({ charge }: any) => (
  <PropertyLine title={t(`${localeStringRoot}.details_labels.paywall_name`)}>
    <Typography.LabelSm>
      {charge.paywall.name || charge.paywall.display_name}
    </Typography.LabelSm>
  </PropertyLine>
);
