import { useMemo } from "react";
import { t } from "i18n-js";
import { flatten, isFunction } from "lodash";
import { useInvitationLinksInfiniteQuery } from "@circle-react/components/SettingsApp/InvitationLinks/InvitationLinksTable/useInvitationLinksInfiniteQuery";
import type { InvitationLinkProps } from "@circle-react/components/SettingsApp/InvitationLinks/interfaces";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Loader } from "@circle-react-uikit/Loader";
import type { BaseFilter } from "../types";

const localeNamespace =
  "members_directory.header.search.filters.invitation_link_ids";

interface InvitationLinksFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter) => void;
  param: BaseFilter;
}

const generateCheckboxData = (record: InvitationLinkProps) => ({
  richLabel: (
    <Typography.LabelMd as="div" weight="medium" color="text-selector-active">
      {record.name}
    </Typography.LabelMd>
  ),
  label: `${record.name}`,
  value: record.id?.toString() ?? "",
});

export const InvitationLinksFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: InvitationLinksFilterProps) => {
  const { data, isLoading } = useInvitationLinksInfiniteQuery();

  const invitationLinks = useMemo(() => {
    const flatInvitationLinks = flatten(data?.pages.map(page => page.records));
    return flatInvitationLinks.map(generateCheckboxData);
  }, [data]);

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={invitationLinks} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {isLoading ? (
        <Loader />
      ) : (
        <SingleSelectFilter
          options={invitationLinks}
          legend={t([localeNamespace, "singular_legend"])}
          param={param}
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
        />
      )}
    </Filter>
  );
};
