import { t } from "i18n-js";
import { noop } from "lodash";
import "@circle-react-shared/PlusUpgradeBanners/shared/BannerBackgrounds.css";
import { Button } from "@circle-react-uikit/Button";

interface BrandSettingsBannerProp {
  title?: string;
  buttonText?: string;
  onClick?: () => void;
  className?: string;
}

const i18nRoot = "settings.general.branding.plus_banner";

export const BannerComponent = ({
  title,
  onClick,
  className,
  buttonText,
}: BrandSettingsBannerProp = {}) => (
  <div
    className={`brand-settings-custom-backround flex flex-col gap-y-3 rounded-2xl bg-[#191B1F] bg-contain bg-right bg-no-repeat px-8 py-6 ${className}`}
  >
    <div className="w-1/2 text-base font-bold text-[#FFFFFF] md:text-lg">
      {title || t([i18nRoot, "title"])}
    </div>
    <div>
      <Button
        small
        variant="secondary-light"
        className="font-medium"
        type="button"
        onClick={onClick || noop}
      >
        {buttonText || t([i18nRoot, "button"])}
      </Button>
    </div>
  </div>
);
