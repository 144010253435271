import classNames from "classnames";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { CommunityMember } from "@circle-react/types";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberCardImage } from "./MemberCardImage";

export interface MemberCardProps {
  member: CommunityMember;
}

export const MemberCard = ({ member }: MemberCardProps) => {
  const { isV3Enabled } = useIsV3();

  const profileLinkProps = useProfileLinkProps({
    public_uid: member.public_uid ?? "",
  });

  return (
    <li
      className={classNames(
        "bg-primary border-primary hover:shadow-c-card border",
        {
          "rounded-lg": !isV3Enabled,
          "rounded-2xl": isV3Enabled,
        },
      )}
      data-testid="community_member_grid"
    >
      <ProfileHoverCard
        communityMemberId={member.id}
        name={member.name}
        publicUid={member.public_uid}
        headline={member.headline}
        avatarURL={member.avatar_url}
      >
        <a {...profileLinkProps} className="flex flex-col" title={member.name}>
          <div style={{ aspectRatio: "1 / 1" }}>
            <MemberCardImage
              name={member.name ?? ""}
              src={member.avatar_url ?? ""}
            />
          </div>
          <div className="flex flex-col px-4 pb-4 pt-3">
            <Typography.BodySm truncate as="h2">
              {member.name}
            </Typography.BodySm>
            <Typography.BodySm truncate>{member.headline}</Typography.BodySm>
          </div>
        </a>
      </ProfileHoverCard>
    </li>
  );
};
