import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Typography } from "@circle-react-uikit/Typography";

interface FilterContainerProps {
  title: string;
  children?: ReactNode;
}

export const FilterContainer = ({ title, children }: FilterContainerProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div className="flex flex-col">
      <span
        className={classNames("inline-block", {
          "px-5": !isV3Enabled,
          "px-4": isV3Enabled,
        })}
      >
        <Typography.LabelXxs color="text-light">{title}</Typography.LabelXxs>
      </span>
      <div>{children}</div>
    </div>
  );
};
