import { t } from "i18n-js";
import { PaymentMethodType } from "@circle-react/helpers/paywallCheckoutHelpers";
import { CreditCardIcon } from "@circle-react-shared/CreditCardIcon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const CardProperty = ({ charge, creditCardIconClassName }: any) => {
  const communityMemberPaymentMethod = charge?.community_member_payment_method;
  const walletType = [
    PaymentMethodType.APPLE_PAY,
    PaymentMethodType.GOOGLE_PAY,
    PaymentMethodType.LINK,
  ].includes(charge?.payment_method_type)
    ? charge.payment_method_type
    : null;

  return communityMemberPaymentMethod ? (
    <div className="flex flex-row items-center">
      <CreditCardIcon
        className={`mr-1 ${creditCardIconClassName}`}
        walletType={walletType}
        card={communityMemberPaymentMethod?.brand}
      />
      <Typography.BodySm>
        {t(
          `${localeStringRoot}.details_labels.payment_method.credit_card_ending`,
          {
            last4: communityMemberPaymentMethod?.last4,
          },
        )}
      </Typography.BodySm>
    </div>
  ) : (
    <div className="flex flex-row gap-x-1">
      <Icon type="credit-card" size={16} />
      <Typography.LabelSm>
        {t(`${localeStringRoot}.details_labels.payment_method.card`)}
      </Typography.LabelSm>
    </div>
  );
};
