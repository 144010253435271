import { Fragment } from "react";
import PropTypes from "prop-types";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import I18n from "i18n-js";
import { get } from "lodash";
import { popOverInOut } from "@circle-react/components/shared/uikit/animations";
import { Icon } from "@circle-react-shared/Icon";
import { useMemberFilterUrl } from "@circle-react-shared/MemberFilters/useMemberFilterUrl";
import { Button } from "@circle-react-uikit/Button";
import { MultiselectFilter } from "./MultiselectFilter";
import { SelectFilter } from "./SelectFilter";
import { TextFilter } from "./TextFilter";
import "./styles.scss";

const localeNamespace = "members_directory.header.search.filters";

export const DropdownFilter = ({ queryParam, type, options }) => {
  const { filterParams, updateUrl } = useMemberFilterUrl();
  const currentFilter = get(filterParams, queryParam, "");

  const onChangeFilter = value => {
    applyFilter(value);
  };

  const applyFilter = filterValue => {
    updateUrl(queryParam, filterValue);
  };

  const clearFilter = () => {
    onChangeFilter("");
  };

  const isMultiselect = type === "multiselect";

  if (!!currentFilter && !isMultiselect) {
    return (
      <div className="react-dropdown-filter">
        <div className="react-dropdown-filter__wrapper react-dropdown-filter__wrapper--active">
          <Button variant="secondary" onClick={clearFilter}>
            <span className="truncate">{currentFilter}</span>
            <Icon
              size={20}
              className="react-dropdown-filter__icon"
              type="close"
            />
          </Button>
        </div>
      </div>
    );
  }

  const filterComponent = close => {
    switch (type) {
      case "text":
        return <TextFilter onChange={onChangeFilter} />;
      case "select":
        return <SelectFilter onChange={onChangeFilter} options={options} />;
      case "multiselect":
        return (
          <MultiselectFilter
            onChange={onChangeFilter}
            options={options}
            currentFilter={currentFilter.split(",")}
            closePopover={close}
          />
        );
    }
  };

  const title = isMultiselect
    ? I18n.t(`${localeNamespace}.${queryParam}.title`)
    : I18n.t(`${localeNamespace}.${queryParam}`);

  const activeButtonTitle = isMultiselect
    ? I18n.t(`${localeNamespace}.${queryParam}`, {
        count: (currentFilter && currentFilter.split(",")).length,
      })
    : I18n.t(`${localeNamespace}.${queryParam}`);

  return (
    <Popover as="div" className="react-dropdown-filter">
      {({ open }) => (
        <div
          className={classnames("react-dropdown-filter__wrapper", {
            "react-dropdown-filter__wrapper--active": !!currentFilter.length,
            "react-dropdown-filter__wrapper--open": open,
          })}
        >
          <Popover.Button
            as={Button}
            variant="secondary"
            className="flex items-center"
          >
            <span>{activeButtonTitle}</span>
            <ChevronDownIcon
              className="react-dropdown-filter__icon"
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition as={Fragment} show={open} {...popOverInOut}>
            <Popover.Panel className="react-dropdown-filter__panel">
              {({ close }) => (
                <div className="react-dropdown-filter__panel-inner">
                  <label htmlFor="" className="react-dropdown-filter__label">
                    {title}
                  </label>
                  {filterComponent(close)}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

DropdownFilter.propTypes = {
  queryParam: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array,
};
