import { t } from "i18n-js";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const CreatedAtProperty = ({ charge }: any) => {
  const dateTime = new Date(charge.created_at);
  const createdAtString = formattedDateTime({
    dateTime,
    format: "long_date_short_time",
  });

  return (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.transaction_date`)}
    >
      <Typography.LabelSm>{createdAtString}</Typography.LabelSm>
    </PropertyLine>
  );
};
