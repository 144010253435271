import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { usePunditUserContext } from "@circle-react/contexts";

export const useShouldShowLevelFilter = filters => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isGamificationFlagEnabled =
    currentCommunitySettings?.gamification_enabled;
  const { data: gamificationSettings } = useGamificationSettings();

  if (isGamificationFlagEnabled && gamificationSettings?.enabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "level");
};
