import { t } from "i18n-js";
import { isFunction } from "lodash";
import { Filter, InputFilterComparator } from "@circle-react-shared/Filter";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator";
import type { StringComparatorParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";

const localeNamespace = "members_directory.header.search.filters";

interface HeadlineFilterProps {
  isInitialOpen?: boolean;
  onApply?: (param: StringComparatorParam) => void;
  param: StringComparatorParam;
}

export const HeadlineFilter = ({
  isInitialOpen,
  onApply,
  param,
}: HeadlineFilterProps) => (
  <Filter
    chip={t([localeNamespace, "headline"])}
    title={t([localeNamespace, "headline_title"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
    renderSelectedText={() => renderTextSelectedItem(param)}
  >
    <InputFilterComparator
      name="headline"
      param={param}
      placeholder={t([localeNamespace, "headline_placeholder"])}
    />
  </Filter>
);
