import type { ReactNode } from "react";
import { values } from "lodash";
import { NavLink } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers/settingsNavPath";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { BACK_TO_COMMUNITY_PATH_KEY } from "./useCollapsedSidebarLinksV2";

export interface NavLinkWrapperProps {
  children: ReactNode;
  path: string;
  pathKey?: string;
}

export const NavLinkWrapper = ({
  children,
  path,
  pathKey,
}: NavLinkWrapperProps) => {
  const { isV3Enabled } = useIsV3();
  const { currentCommunity } = usePunditUserContext();

  const shouldRenderTraditionalAnchorTag =
    pathKey === BACK_TO_COMMUNITY_PATH_KEY &&
    !currentCommunity?.home_page_enabled;

  const links = isV3Enabled ? settingsNavPathV3 : settingsNavPath;

  if (!shouldRenderTraditionalAnchorTag) {
    return (
      <NavLink
        exact
        to={path}
        className="hover:bg-secondary hover:border-dark flex items-center justify-center rounded-lg border border-transparent p-2.5 transition-all duration-150 ease-linear"
        activeClassName="bg-tertiary"
        isActive={(match, location) => {
          const hasMatch = Boolean(match);
          const routes: any = pathKey
            ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Hard to refactor, disabling since v2 will be sunset
              links[pathKey as keyof typeof links]
            : null;

          const isPathInLocation = values(routes).some(
            route => location?.pathname === route,
          );

          return hasMatch || isPathInLocation;
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <a
      href={path}
      className="hover:bg-secondary hover:border-dark active:bg-tertiary flex items-center justify-center rounded-lg border border-transparent p-2.5 transition-all duration-150 ease-linear"
    >
      {children}
    </a>
  );
};
