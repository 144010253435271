import { t } from "i18n-js";
import { Modal_DEPRECATED } from "../Modal";

/** @deprecated Please use the new confirmation modal at app/javascript/react/components/shared/ModalsV2/ConfirmationModal */
export const ConfirmationModal = ({
  show,
  className,
  closeOnEscape = false,
  closeOnOutsideClick = false,
  onClose,
  onConfirmation,
  headerText,
  bodyText,
  closeBtnClass = "btn btn-default",
  confirmBtnClass = "btn btn-primary",
}) => (
  <Modal_DEPRECATED
    className={className}
    show={show}
    onClose={onClose}
    closeOnOutsideClick={closeOnOutsideClick}
    closeOnEscape={closeOnEscape}
  >
    <Modal_DEPRECATED.Header onClose={onClose}>
      <h2 className="modal__title">{headerText}</h2>
    </Modal_DEPRECATED.Header>
    <Modal_DEPRECATED.Body>
      <span>{bodyText}</span>
      <div className="confirm-btn-row">
        <button type="submit" className={closeBtnClass} onClick={onClose}>
          {t("cancel")}
        </button>
        <button
          type="submit"
          className={confirmBtnClass}
          onClick={onConfirmation}
        >
          {t("confirm")}
        </button>
      </div>
    </Modal_DEPRECATED.Body>
  </Modal_DEPRECATED>
);
