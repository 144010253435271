import { useCallback, useMemo, useState } from "react";
import { nameToHslColor, nameToInitials } from "@circleco/ui-kit";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

interface MemberCardImageProps {
  name: string;
  src: string;
}

export const MemberCardImage = ({ src, name }: MemberCardImageProps) => {
  const hasImage = Boolean(src);
  const [hasError, setHasError] = useState(false);
  const { isV3Enabled } = useIsV3();

  const handleOnError = useCallback(() => {
    setHasError(true);
  }, []);

  const initials = useMemo(() => nameToInitials(name), [name]);

  const style = useMemo(
    () => ({ backgroundColor: nameToHslColor(name) }),
    [name],
  );

  const shouldShowImage = hasImage && !hasError;

  return (
    <div
      className="relative isolate block h-full w-full object-cover"
      data-testid="user-profile"
    >
      {shouldShowImage && (
        <img
          loading="lazy"
          className={classNames(
            "absolute left-0 top-0 z-10 h-full w-full object-cover",
            {
              "rounded-t-md": !isV3Enabled,
              "rounded-t-2xl": isV3Enabled,
            },
          )}
          src={src}
          onError={handleOnError}
          alt={name}
        />
      )}
      <div
        className={classNames(
          "z-10 flex h-full w-full items-center justify-center text-xl font-medium tracking-normal text-white",
          {
            invisible: shouldShowImage,
            "rounded-t-md": !isV3Enabled,
            "rounded-t-2xl": isV3Enabled,
          },
        )}
        style={style}
      >
        {initials}
      </div>
    </div>
  );
};
