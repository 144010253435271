import { create } from "zustand";

export type NodeTypes = "trigger" | "action" | "audience";

interface ActionParams {
  action: any;
  index: number;
}

interface ActionNodeUpdateParams {
  nodeId?: string;
  isUpdating: boolean;
}

interface DrawerStore {
  isOpen: boolean;
  toggle: () => void;

  nodeType: NodeTypes | null;
  setNodeType: (type: NodeTypes | null) => void;

  actionParams: ActionParams | null;
  setActionParams: (params: ActionParams | null) => void;

  selectedEdgeId: string | null;
  setSelectedEdgeId: (id: string | null) => void;

  isUpdatingBaseNode: boolean;
  setIsUpdatingBaseNode: (isUpdating: boolean) => void;

  actionNodeUpdateParams: ActionNodeUpdateParams;
  setActionNodeUpdateParams: (params: ActionNodeUpdateParams) => void;

  resetCanvasState: () => void;

  setInitialNodeType: (type: NodeTypes) => void;
  isNodeSelectedOfType: (type: NodeTypes) => boolean;
}

export const useDrawerStore = create<DrawerStore>((set, get) => ({
  isOpen: false,
  toggle: () => {
    set({ isOpen: !get().isOpen });
  },

  nodeType: null,
  setNodeType: type => set({ nodeType: type }),
  setInitialNodeType: type => set({ nodeType: type, isOpen: true }),
  isNodeSelectedOfType: (type: NodeTypes) => get().nodeType === type,

  actionParams: null,
  setActionParams: params => set({ actionParams: params }),

  selectedEdgeId: null,
  setSelectedEdgeId: id => set({ selectedEdgeId: id }),

  isUpdatingBaseNode: false,
  setIsUpdatingBaseNode: isUpdating => set({ isUpdatingBaseNode: isUpdating }),

  actionNodeUpdateParams: { nodeId: undefined, isUpdating: false },
  setActionNodeUpdateParams: params => set({ actionNodeUpdateParams: params }),

  resetCanvasState: () =>
    set(state => ({
      ...state,
      selectedEdgeId: null,
      actionParams: null,
      nodeType: null,
    })),
}));
