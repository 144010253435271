import PropTypes from "prop-types";
import { t } from "i18n-js";
import Truncate from "react-truncate";
import { useBoolean } from "@/react/hooks/utils/useBoolean";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Typography } from "@circle-react-uikit/Typography";

export const Bio = ({ text }) => {
  const [isExpanded, toggle] = useBoolean(false);
  const isSmScreen = useSmScreenMediaQuery();

  if (!text) {
    return null;
  }

  const expandText = event => {
    event.stopPropagation();
    toggle();
  };

  if (isSmScreen) return null;

  return (
    <Typography.LabelSm>
      <div className="w-full text-left">
        <Truncate
          lines={!isExpanded && 2}
          ellipsis={
            <>
              <span>... </span>
              <button
                type="submit"
                className="font-medium"
                onClick={expandText}
              >
                {t("see_more")}
              </button>
            </>
          }
        >
          <div className="[word-break:break-word]">{text}</div>
        </Truncate>
      </div>
    </Typography.LabelSm>
  );
};

Bio.propTypes = {
  text: PropTypes.string,
};
