import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";
import { Item } from "./Item";

export interface MembersPreviewProps extends ComponentPropsWithoutRef<"div"> {}

export const MembersPreview = ({ className, ...rest }: MembersPreviewProps) => (
  <div
    className={classNames(
      "border-primary bg-primary h-[276px] space-y-5 rounded-lg border px-5 py-4",
      className,
    )}
    {...rest}
  >
    <div className="flex h-5 items-center">
      <Typography.LabelMd weight="semibold">{t("members")}</Typography.LabelMd>
    </div>
    <Item className="w-[128px]" />
    <Item className="w-[102px]" />
    <Item className="w-[119px]" />
    <Item className="w-[84px]" />
    <Item className="w-[128px]" />
  </div>
);
