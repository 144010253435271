import { CommunitySectionPresentation } from "@circle-react/components/CommunitySwitcher/CommunitySection/CommunitySectionPresentation";
import { CommunitySwitcherPresentation } from "@circle-react/components/CommunitySwitcher/CommunitySwitcherPresentation";
import { CommunitySwitcherPresentationV3 } from "@circle-react/components/CommunitySwitcher/CommunitySwitcherPresentationV3";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface CommunitySwitcherPreviewProps {
  brandColor?: string;
  href?: string;
  iconUrl?: string | null;
  includeName?: boolean;
  isActive?: boolean;
  logoUrl?: string | null;
  name?: string;
  notificationCount?: number;
}

export const CommunitySwitcherPreview = ({
  brandColor = "#506cf0",
  href = "#",
  iconUrl = "",
  includeName = false,
  isActive = true,
  logoUrl = "",
  name = "Community Name",
  notificationCount = 0,
}: CommunitySwitcherPreviewProps) => {
  const { isV3Enabled } = useIsV3();

  const PresentationComponent = isV3Enabled
    ? CommunitySwitcherPresentationV3
    : CommunitySwitcherPresentation;

  return (
    <PresentationComponent
      isLoading={false}
      className="pointer-events-none !block"
    >
      <CommunitySectionPresentation
        isActive={isActive}
        includeName={includeName}
        brand_color={brandColor}
        name={name}
        href={href}
        icon_attachment_url={iconUrl}
        logo_attachment_url={logoUrl}
        notificationCount={notificationCount}
      />
      <CommunitySectionPresentation
        isActive={false}
        includeName={false}
        brand_color="#9ca3af"
        name="Community 2"
        href="#"
        icon_attachment_url=""
        logo_attachment_url=""
        notificationCount={0}
      />
      <CommunitySectionPresentation
        isActive={false}
        includeName={false}
        brand_color="#9ca3af"
        name="Community 3"
        href="#"
        icon_attachment_url=""
        logo_attachment_url=""
        notificationCount={0}
      />
    </PresentationComponent>
  );
};
