import { useEffect } from "react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";

const LOCALE_ROOT = "export_paywall_csv.modal.timezone_options";

export const TimezoneOptionsForm = () => {
  const { setValue } = useFormContext();

  const currentTimezoneOption = {
    label: t([LOCALE_ROOT, "current_timezone"], {
      timezone: new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          timeZoneName: "short",
        })
        .slice(4),
    }),
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  useEffect(() => {
    setValue("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  return (
    <div className="flex flex-col gap-y-4 pt-4">
      <Typography.LabelMd weight="bold">
        {t("export_paywall_csv.modal.timezone_options_form.timezone")}
      </Typography.LabelMd>
      <Form.RadioGroup
        variant="radio"
        name="timezone"
        options={[
          currentTimezoneOption,
          {
            label: t("export_paywall_csv.modal.timezone_options.utc"),
            value: "Etc/UTC",
          },
        ]}
      />
    </div>
  );
};
