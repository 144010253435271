import { t } from "i18n-js";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { ENDS_AT_OPTIONS } from "../../constants";

export const useCancelOptionsForm = ({ subscription }) => {
  const cancelOptions = [
    {
      description: "",
      label: t(
        "settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal.cancel_options.cancel_immediately",
        {
          date: formattedDateTime({
            dateTime: new Date(),
            format: "long_date",
          }),
        },
      ),
      value: ENDS_AT_OPTIONS.NOW,
    },
  ];

  if (subscription.cancelable_at_period_end) {
    cancelOptions.push({
      description: "",
      label: t(
        "settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal.cancel_options.cancel_at_end_of_period",
        {
          date: formattedDateTime({
            dateTime: new Date(subscription.renews_on),
            format: "long_date",
          }),
        },
      ),
      value: ENDS_AT_OPTIONS.AT_PERIOD_END,
    });
  }

  return {
    cancelOptions,
  };
};
