import { REFETCH_INTERVAL } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { numberFormatter } from "@circle-react-shared/uikit/TabV2/Counter";
import { useRecipientListInfiniteQuery } from "./useRecipientListInfiniteQuery";

export const useViewRecipientList = ({
  id,
  event_type,
  email,
  link,
}: {
  id: number;
  event_type: string;
  email?: string;
  link?: string;
}) => {
  const filters: { email: string; link: string } = {
    email: email ? email : "",
    link: link ? link : "",
  };

  const { totalQueryMembers, ...membersQuery } = useRecipientListInfiniteQuery({
    id,
    event_type,
    filters,
    options: {
      refetchInterval: REFETCH_INTERVAL,
    },
  });

  const formattedCount = numberFormatter(totalQueryMembers);

  return {
    formattedCount,
    count: totalQueryMembers,
    ...membersQuery,
  };
};
