import { t } from "i18n-js";
import { isFunction } from "lodash";
import {
  Filter,
  RadioFilterV2 as RadioFilter,
} from "@circle-react-shared/Filter";
import type {
  RadioFilterOptions,
  RadioFilterParam,
} from "@circle-react-shared/Filter/FilterTypes/RadioFilterV2";

const localeNamespace = "members_directory.header.search.filters";

const options: RadioFilterOptions = [
  {
    label: t("boolean_filter_options.yes"),
    value: "true",
  },
  {
    label: t("boolean_filter_options.no"),
    value: "false",
  },
];

export const renderSelectedOptionLabel = (selectedValue: string) => {
  const selectedOption = options.find(option => option.value === selectedValue);
  return selectedOption ? selectedOption.label : "";
};

interface AvatarSetProps {
  isInitialOpen?: boolean;
  onApply?: (value: RadioFilterParam) => void;
  param: RadioFilterParam;
}

export const AvatarSet = ({
  isInitialOpen = false,
  onApply,
  param,
}: AvatarSetProps) => (
  <Filter
    chip={t([localeNamespace, "avatar_set"])}
    title={t([localeNamespace, "avatar_set"])}
    selectedText={param.value}
    renderSelectedText={({ selectedText }) =>
      renderSelectedOptionLabel(selectedText)
    }
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <RadioFilter
      options={options}
      legend={t([localeNamespace, "avatar_set_legend"])}
      param={param}
    />
  </Filter>
);
