import PropTypes from "prop-types";
import { t } from "i18n-js";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useMemberCSV } from "@circle-react/hooks/members/useMembersCSV";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export const DownloadMembersCSVButton = ({ spaceId, spaceGroupId }) => {
  const { downloadMembersCSV } = useMemberCSV();

  const downloadURL = () => {
    if (spaceGroupId) {
      return internalApi.spaceGroups.exportMembers({ id: spaceGroupId });
    }
    return internalApi.spaces.exportMembers({ id: spaceId });
  };

  return (
    <button
      type="submit"
      className="text-dark hover:text-darkest inline-flex items-center"
      onClick={() => downloadMembersCSV({ downloadURL: downloadURL() })}
    >
      <Typography.LabelSm>{t("download_csv")}</Typography.LabelSm>
      <Icon
        className="text-dark ml-2 inline-block"
        type="16-download"
        size={16}
      />
    </button>
  );
};

DownloadMembersCSVButton.propTypes = {
  spaceId: PropTypes.number,
  spaceGroupId: PropTypes.number,
};
