import type { CSSProperties, ReactNode } from "react";
import { memo, useMemo } from "react";
import FrameComponent from "react-frame-component";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

const getParentStylesHTML = () => {
  let styleElementsHTML = "";

  const stylesheetLinks = Array.from(
    document.querySelectorAll("link[rel=stylesheet]"),
  );
  const headStyleTags = Array.from(document.querySelectorAll("head style"));

  stylesheetLinks.forEach(link => {
    styleElementsHTML += link.outerHTML;
  });

  headStyleTags.forEach(styleElement => {
    styleElementsHTML += styleElement.outerHTML;
  });

  return styleElementsHTML;
};

const getSvgSpriteHTML = () => {
  const spriteElement = document.getElementById("icon-svg-sprite");
  return spriteElement?.outerHTML ?? "";
};

const getV3FontFamilyStyles = (isV3Enabled = false) => {
  if (!isV3Enabled) {
    return "";
  }
  return `:root {
    --font-system-ui: InterVariable;
  }`;
};

const mountPointId = "frame-mount-point";
const mountPointSelector = `#${mountPointId}`;

const getInitialContent = (isV3Enabled: boolean) => `
  <!DOCTYPE html>
  <html>
    <head>
      ${getParentStylesHTML()}
      <style>
        ${getV3FontFamilyStyles(isV3Enabled)}
        ${mountPointSelector} .frame-content {
          width: 100%;
          height: 100%;
          position: relative;
          isolation: isolate;
        }
      </style>
    </head>
    <body class="w-full h-full">
      ${getSvgSpriteHTML()}
      <div id="${mountPointId}" class="h-screen"></div>
    </body>
  </html>
`;

const responsiveFrame: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export interface StyledFrameProps {
  children: ReactNode;
}

export const StyledFrame = memo(({ children, ...rest }: StyledFrameProps) => {
  const { isV3Enabled } = useIsV3();
  const initialContent = useMemo(
    () => getInitialContent(isV3Enabled),
    [isV3Enabled],
  );

  return (
    <FrameComponent
      initialContent={initialContent}
      mountTarget={mountPointSelector}
      style={responsiveFrame}
      {...rest}
    >
      {children}
    </FrameComponent>
  );
});

StyledFrame.displayName = "StyledFrame";
