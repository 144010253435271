import { t } from "i18n-js";
import { isFunction } from "lodash";
import {
  Filter,
  RadioFilterV2 as RadioFilter,
} from "@circle-react-shared/Filter";
import type {
  RadioFilterOptions,
  RadioFilterParam,
} from "@circle-react-shared/Filter/FilterTypes/RadioFilterV2";

const localeNamespace = "members_directory.header.search.filters";

const options: RadioFilterOptions = [
  {
    label: t("boolean_filter_options.yes"),
    value: "true",
  },
  {
    label: t("boolean_filter_options.no"),
    value: "false",
  },
];

interface HasPastDueSubscriptionFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value: RadioFilterParam) => void;
  param: RadioFilterParam;
}

export const HasPastDueSubscriptionFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: HasPastDueSubscriptionFilterProps) => (
  <Filter
    chip={t([localeNamespace, "has_past_due_subscription"])}
    title={t([localeNamespace, "has_past_due_subscription"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <RadioFilter
      options={options}
      param={param}
      legend={t([localeNamespace, "has_past_due_subscription_legend"])}
    />
  </Filter>
);
