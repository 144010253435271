import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useThemeContext } from "@circle-react/providers";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Logo } from "./Logo";
import { SwitcherCount } from "./SwitcherCount";
import { TippyContent } from "./TippyContent";

export interface CommunityLogoProps {
  brand_color?: string;
  brand_text_color?: string;
  light_brand_color?: string;
  dark_brand_color?: string;
  icon_attachment_url?: string | null;
  includeName?: boolean;
  index?: number;
  logo_attachment_url?: string | null;
  name?: string;
  notificationCount?: number;
  shouldShowTooltip?: boolean;
}

export const CommunityLogo = ({
  brand_color,
  brand_text_color: textColor,
  light_brand_color: lightBgColor,
  dark_brand_color: darkBgColor,
  icon_attachment_url: iconUrl,
  includeName = false,
  index,
  logo_attachment_url: logoURL,
  name = "",
  notificationCount = 0,
  shouldShowTooltip = true,
}: CommunityLogoProps) => {
  const { isV3Enabled } = useIsV3();
  const { isDarkAppearance } = useThemeContext();
  const bgColor = isDarkAppearance ? darkBgColor : lightBgColor || brand_color;

  return (
    <div
      data-testid="community-switcher-item"
      className={classNames({
        "switcher__tippy-parent": !isV3Enabled,
      })}
    >
      <TippyV2
        content={<TippyContent index={index} name={name} />}
        placement="right"
        arrow={false}
        offset={[0, 16]}
        interactive={false}
        enabled={shouldShowTooltip}
      >
        <Logo
          logoURL={logoURL}
          name={name}
          iconUrl={iconUrl}
          textColor={textColor}
          bgColor={bgColor}
          includeName={includeName}
        />
        {notificationCount > 0 && <SwitcherCount />}
      </TippyV2>
    </div>
  );
};
