import { t } from "i18n-js";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const CommunityBotLinks = () => {
  const links: Link[] = [
    {
      label: t("settings_nav_v3.community_bot.knowledge"),
      path: settingsNavPathV3.community_bot.knowledge,
      exact: true,
    },
    {
      label: t("settings_nav_v3.community_bot.agents"),
      path: settingsNavPathV3.community_bot.agents,
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.community_bot.label")}>
        {links.map(link => (
          <SubNavLinkItem
            key={link.path}
            label={link.label}
            path={link.path}
            isNewFeature={!!link.isNewFeature}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
