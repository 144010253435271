import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Option } from "./Option";
import "./styles.scss";

export const MultiselectFilter = ({
  options = [],
  currentFilter = [],
  onChange,
  closePopover,
}) => {
  const [value, setValue] = useState(currentFilter);
  const [searchTerm, setSearchTerm] = useState("");
  const ref = useRef();

  const filteredList = options.filter(option =>
    !searchTerm
      ? option
      : option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleOnChange = (updatedValue, checked) => {
    const newValue = checked
      ? [...value, updatedValue]
      : value.filter(option => option !== updatedValue);
    setValue(newValue);
  };

  const selectedOption = option => currentFilter.includes(option);

  const handleSubmit = () => {
    const filterValue = value.filter(filter => !!filter).join(",");

    onChange(filterValue);
    closePopover();
  };

  useEffect(() => {
    ref.current?.focus();
  }, [ref]);

  return (
    <div className="react-multiselect-filter">
      <input
        type="text"
        ref={ref}
        className="form-control form-control--sm placeholder:text-light placeholder:opacity-100"
        onChange={e => setSearchTerm(e.target.value)}
        placeholder={t("members_directory.header.search.search")}
      />

      <div className="react-multiselect-filter__options">
        {filteredList.map((option, index) => (
          <div key={index} className="react-multiselect-filter__item">
            <div className="react-multiselect-filter__item-inner">
              <Option
                option={option}
                onChange={handleOnChange}
                selected={selectedOption(option.value.toString())}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="mt-3">
        <Button variant="primary" type="large" onClick={handleSubmit}>
          {t("members_directory.header.search.apply_filter")}
        </Button>
      </div>
    </div>
  );
};

MultiselectFilter.propTypes = {
  options: PropTypes.array,
  currentFilter: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  closePopover: PropTypes.func.isRequired,
};
