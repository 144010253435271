import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { couponsApi } from "@circle-react/api/admin/paywalls/subscriptions/coupons/couponsApi";
import { BigLoader } from "@circle-react-shared/BigLoader";
import { PaymentsSecureAndEncrypted } from "@circle-react-shared/Paywalls/PaymentsSecureAndEncrypted";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useApplyCouponContext } from "../ApplyCouponContextProvider";
import { ApplyCouponInvoice } from "../Invoice/ApplyCouponInvoice";
import { RemoveCouponInvoice } from "../Invoice/RemoveCouponInvoice";
import { ApplyingCouponStep } from "./ApplyingCouponStep";

const localeRoot = "paywalls.apply_coupon_modal.preview_coupon_step";

const stepProperties = {
  step: "PreviewCouponStep",
  title: t([localeRoot, "title"]),
};

const Component = ({ subscription }: any) => {
  const {
    getCurrentPage,
    clearAndSetNewPageHistory,
    onSubmit,
    onClose,
    shouldRemoveCoupon,
  } = useApplyCouponContext();
  const { watch, handleSubmit } = useFormContext();
  const couponCode = shouldRemoveCoupon ? null : watch("coupon_code");
  const onClick = () => {
    clearAndSetNewPageHistory(ApplyingCouponStep.stepProperties);
    void handleSubmit(onSubmit)();
  };

  const previewCouponQuery = useQuery({
    queryKey: ["preview-coupon-query", subscription.processor_id, couponCode],
    queryFn: couponsApi.preview({
      subscriptionProcessorId: subscription.processor_id,
      couponCode,
    }),
  });

  if (getCurrentPage().step !== stepProperties.step) return null;
  if (previewCouponQuery.isLoading) {
    return <Loader />;
  }

  if (previewCouponQuery.isError) {
    return (
      <>
        <Modal.Body>
          <BigLoader
            title={t([localeRoot, "failed.title"])}
            subtitle={t([
              localeRoot,
              `failed.subtitle_${
                shouldRemoveCoupon ? "removal" : "application"
              }`,
            ])}
            variant="error"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button full large variant="circle" type="button" onClick={onClose}>
            {t(["close"])}
          </Button>
        </Modal.Footer>
      </>
    );
  }

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col gap-y-4">
          {shouldRemoveCoupon ? (
            <RemoveCouponInvoice
              subscription={subscription}
              previewCouponQuery={previewCouponQuery}
            />
          ) : (
            <ApplyCouponInvoice
              subscription={subscription}
              previewCouponQuery={previewCouponQuery}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          full
          large
          variant="circle"
          disabled={false}
          type="submit"
          onClick={onClick}
        >
          {t([localeRoot, "button"])}
        </Button>
        <PaymentsSecureAndEncrypted />
      </Modal.Footer>
    </>
  );
};

export const PreviewCouponStep = Object.assign(Component, {
  stepProperties,
});
