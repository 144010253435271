import { t } from "i18n-js";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useBroadcastReport } from "../BroadcastReportContext";
import { LinkClickStatsTable } from "./LinkClickStatsTable";
import { NoLinksFound } from "./NoLinksFound";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.links_table";

export const LinkClicksStats = () => {
  const { data: { link_clicks: linkClicks = [] } = {} } = useBroadcastReport();

  const shouldShowLinkStats = linkClicks.length > 0;

  return (
    <div className="rounded-lg border p-6">
      {shouldShowLinkStats ? (
        <>
          <div className="mb-4">
            <Typography.LabelMd weight="semibold" color="text-very-dark">
              {t([localeNamespace, "title"])}
            </Typography.LabelMd>
          </div>
          <LinkClickStatsTable />
        </>
      ) : (
        <NoLinksFound />
      )}
    </div>
  );
};
