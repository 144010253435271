import { t } from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import { RecipientsProcessingMessage } from "@circle-react/components/SettingsApp/Emails/components/RecipientsProcessingMessage";
import { getAudienceRole } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Cells/functions";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import type {
  AudienceRoles,
  AudienceType,
} from "@circle-react/types/AudienceList";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Table } from "@circle-react-uikit/TableV2";
import { Typography } from "@circle-react-uikit/Typography";

interface MatchedRecipientListProps {
  fetchNextPage: () => void;
  searchedMembers: any;
  count: number;
  hasNextPage: boolean;
  children: React.ReactNode;
  isRecipientRecordsProcessing: boolean;
  recipientType: "link_clicks_recipients" | "all_recipients";
}

const localeNamespace = "settings.emails.builder.recipient_modal";

export const MatchedRecipientList = ({
  count,
  hasNextPage,
  fetchNextPage,
  searchedMembers,
  children,
  isRecipientRecordsProcessing,
  recipientType,
}: MatchedRecipientListProps) => (
  <>
    {children}
    {isRecipientRecordsProcessing ? (
      <RecipientsProcessingMessage />
    ) : (
      <InfiniteScroll
        next={fetchNextPage}
        height="22rem"
        hasMore={Boolean(hasNextPage)}
        loader={<LoaderRow />}
        dataLength={count}
        className="px-8"
      >
        <Table>
          <Table.TableHead className="bg-primary border-primary sticky top-0 z-10 border-t">
            <Table.Tr>
              <Table.Th>
                <Table.TableHeaderText>
                  {t([localeNamespace, "name"])}
                </Table.TableHeaderText>
              </Table.Th>
              <Table.Th>
                <Table.TableHeaderText>
                  {t([localeNamespace, "role"])}
                </Table.TableHeaderText>
              </Table.Th>
              <Table.Th>
                <Table.TableHeaderText>
                  {t([localeNamespace, "last_activity"])}
                </Table.TableHeaderText>
              </Table.Th>
              {recipientType === "link_clicks_recipients" && (
                <Table.Th>
                  <Table.TableHeaderText>
                    {t([localeNamespace, "clicks"])}
                  </Table.TableHeaderText>
                </Table.Th>
              )}
            </Table.Tr>
          </Table.TableHead>
          <Table.TableBody className="divide-primary isolate divide-y">
            {searchedMembers.map(
              ({
                id,
                avatar_url: avatarUrl,
                name,
                email,
                roles,
                type,
                last_event_at: lastEventAt,
                event_count: eventCount,
              }: {
                id: string;
                avatar_url: string;
                name: string;
                email: string;
                roles: AudienceRoles;
                type: AudienceType;
                last_event_at: string;
                event_count: number;
              }) => (
                <Table.Tr key={id}>
                  <Table.Td>
                    <div className="flex flex-row items-start gap-3">
                      <span>
                        <div className="float-left">
                          <UserImage size="10" src={avatarUrl} name={name} />
                        </div>
                      </span>
                      <div className="flex flex-col">
                        <Typography.LabelSm weight="semibold">
                          {name}
                        </Typography.LabelSm>
                        <Typography.LabelSm
                          weight="regular"
                          color="text-default"
                        >
                          {email}
                        </Typography.LabelSm>
                      </div>
                    </div>
                  </Table.Td>
                  <Table.Td>
                    <Typography.LabelSm weight="regular">
                      {getAudienceRole({
                        type: type,
                        roles: roles,
                      })}
                    </Typography.LabelSm>
                  </Table.Td>
                  <Table.Td>
                    <Typography.LabelSm weight="regular">
                      {formattedDateTime({
                        dateTime: new Date(lastEventAt),
                        format: dateFormats.short_date_at_short_time,
                      })}
                    </Typography.LabelSm>
                  </Table.Td>
                  {recipientType === "link_clicks_recipients" && (
                    <Table.Td>
                      <Typography.LabelSm weight="regular">
                        {eventCount}
                      </Typography.LabelSm>
                    </Table.Td>
                  )}
                </Table.Tr>
              ),
            )}
          </Table.TableBody>
        </Table>
      </InfiniteScroll>
    )}
  </>
);
