import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { couponsApi } from "@circle-react/api/admin/paywalls/subscriptions/coupons/couponsApi";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { useApplyCouponContext } from "../ApplyCouponContextProvider";
import { CouponForm } from "../Form/CouponForm";
import { PreviewCouponStep } from "./PreviewCouponStep";

const localeRoot = "paywalls.apply_coupon_modal.select_coupon_step";

const stepProperties = {
  step: "SelectCouponStep",
  title: t([localeRoot, "title"]),
};

const Component = ({ subscription }: any) => {
  const { getCurrentPage, pushNewPageHistory } = useApplyCouponContext();
  const { watch } = useFormContext();
  const couponCode = watch("coupon_code");
  const onClick = () => {
    pushNewPageHistory(PreviewCouponStep.stepProperties);
  };

  const previewCouponQuery = useQuery({
    queryKey: ["preview-coupon-query", subscription.processor_id, couponCode],
    queryFn: couponsApi.preview({
      subscriptionProcessorId: subscription.processor_id,
      couponCode,
    }),
    enabled: !!couponCode,
  });

  if (getCurrentPage().step !== stepProperties.step) {
    return null;
  }

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col gap-y-1">
            <Typography.LabelSm weight="semibold">
              {subscription.paywall.name || subscription.paywall.display_name}
            </Typography.LabelSm>
            <Typography.LabelSm>
              {subscription.paywall_price.short_description}
            </Typography.LabelSm>
          </div>
          <hr />
          <CouponForm paywallId={subscription.paywall.id} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          full
          large
          variant="circle"
          disabled={previewCouponQuery.isLoading || !couponCode}
          type="button"
          onClick={onClick}
        >
          {t([localeRoot, "button"])}
        </Button>
      </Modal.Footer>
    </>
  );
};

export const SelectCouponStep = Object.assign(Component, {
  stepProperties,
});
