import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsSegmentsEnabled } from "@circle-react/hooks/useIsSegmentsEnabled";
import { useMarketingHubBilling } from "@circle-react/hooks/useMarketingHubBilling";
import { MarketingHubFreePeriodBanner } from "@circle-react-shared/MarketingHubFreePeriodBanner";
import { NavGroup } from "../NavGroup";
import { FooterWrapper } from "./FooterWrapper";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const MemberLinks = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { access_groups_enabled: hasAccessGroups } = currentCommunitySettings;
  const { isInFreePeriod } = useMarketingHubBilling();
  const isSegmentsEnabled = useIsSegmentsEnabled();

  const links: Link[] = [
    {
      label: t("settings_nav_v3.members.manage"),
      path: settingsNavPath.members.manage,
      exact: false,
    },
  ];

  if (hasAccessGroups) {
    links.push({
      label: t("settings_nav_v3.members.access_groups"),
      path: settingsNavPath.members.access_groups,
      exact: true,
    });
  }

  if (isSegmentsEnabled) {
    links.push({
      label: t("settings_nav_v3.members.segments"),
      path: settingsNavPath.members.segments,
      exact: false,
    });
  }

  links.push(
    ...[
      {
        label: t("settings_nav_v3.members.bulk_logs"),
        path: settingsNavPath.members.bulk_logs,
        exact: true,
      },
      {
        label: t("settings_nav_v3.members.invitation_links"),
        path: settingsNavPath.members.invitation_links,
        exact: false,
      },
      {
        label: t("settings_nav_v3.members.onboarding"),
        path: settingsNavPath.members.onboarding,
        exact: false,
      },
      {
        label: t("settings_nav_v3.members.member_tags"),
        path: settingsNavPath.members.member_tags,
        exact: false,
      },
      {
        label: t("settings_nav_v3.members.profile_fields"),
        path: settingsNavPath.members.profile_fields,
        exact: true,
      },
      {
        label: t("settings_nav_v3.members.gamification"),
        path: settingsNavPath.members.gamification,
        isDisabled: !currentCommunitySettings?.gamification_enabled,
        exact: true,
      },
      {
        label: t("settings_nav_v3.members.activity_logs"),
        path: settingsNavPath.members.activity_logs,
        exact: true,
      },
    ],
  );

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.members.label")}>
        {links.map(link => (
          <SubNavLinkItem
            key={link.label}
            label={link.label}
            path={link.path}
            isDisabled={link.isDisabled}
            exact={link.exact}
          />
        ))}
        {isInFreePeriod && (
          <FooterWrapper>
            <MarketingHubFreePeriodBanner />
          </FooterWrapper>
        )}
      </NavGroup.Content>
    </NavGroup>
  );
};
