import { t } from "i18n-js";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-uikit/Typography";

const localeStringRoot =
  "member_profile_modal.billing.subscriptions.subscription_details_modal";

export interface RenewsOrEndsAtDetailsProps {
  subscription: {
    renews_on?: string;
    next_billing_date?: string;
    last_billing_date?: string;
    cancels_on?: string;
    canceled_on?: string;
  };
  textColor?: "text-primary" | "text-light";
}
export const RenewsOrEndsAtDetails = ({
  subscription,
  textColor = "text-light",
}: RenewsOrEndsAtDetailsProps) => (
  <>
    {subscription.renews_on && (
      <div>
        <Typography.LabelSm weight="semibold">
          {t([localeStringRoot, "renews_on"])}
        </Typography.LabelSm>
        <Typography.BodySm color={textColor}>
          {formattedDateTime({
            dateTime: new Date(subscription.renews_on),
            format: dateFormats.long_date,
          })}
        </Typography.BodySm>
      </div>
    )}

    {subscription.next_billing_date && (
      <div>
        <Typography.LabelSm weight="semibold">
          {t([localeStringRoot, "next_billing_date"])}
        </Typography.LabelSm>
        <Typography.BodySm color={textColor}>
          {formattedDateTime({
            dateTime: new Date(subscription.next_billing_date),
            format: dateFormats.long_date,
          })}
        </Typography.BodySm>
      </div>
    )}

    {subscription.last_billing_date && (
      <div>
        <Typography.LabelSm weight="semibold">
          {t([localeStringRoot, "last_billing_date"])}
        </Typography.LabelSm>
        <Typography.BodySm color={textColor}>
          {formattedDateTime({
            dateTime: new Date(subscription.last_billing_date),
            format: dateFormats.long_date,
          })}
        </Typography.BodySm>
      </div>
    )}

    {subscription.cancels_on && (
      <div>
        <Typography.LabelSm weight="semibold">
          {t([localeStringRoot, "cancels_on"])}
        </Typography.LabelSm>
        <Typography.BodySm color={textColor}>
          {formattedDateTime({
            dateTime: new Date(subscription.cancels_on),
            format: dateFormats.long_date,
          })}
        </Typography.BodySm>
      </div>
    )}

    {subscription.canceled_on && (
      <div>
        <Typography.LabelSm weight="semibold">
          {t([localeStringRoot, "canceled_on"])}
        </Typography.LabelSm>
        <Typography.BodySm color={textColor}>
          {formattedDateTime({
            dateTime: new Date(subscription.canceled_on),
            format: dateFormats.long_date,
          })}
        </Typography.BodySm>
      </div>
    )}
  </>
);
