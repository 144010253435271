import { t } from "i18n-js";
import { isFunction } from "lodash";
import { ALL_SEGMENTS_QUERY_KEY_PREFIX } from "@circle-react/components/SettingsApp/Segments/SegmentsTable/useSegmentsInfiniteQuery";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useUnPaginateQuery } from "@circle-react/hooks/reactQuery/useUnPaginateQuery";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters.segments";

interface SegmentFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter) => void;
  param: BaseFilter;
}

export const SegmentFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: SegmentFilterProps) => {
  const { records, isLoading } = useUnPaginateQuery({
    api: internalApi.segments.index,
    key: ALL_SEGMENTS_QUERY_KEY_PREFIX,
  });
  const segmentItems = records?.map(segment => ({
    label: segment.title,
    value: segment.id.toString(),
  }));

  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={segmentItems} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {isLoading ? (
        <Loader />
      ) : (
        <SingleSelectFilter
          options={segmentItems || []}
          legend={t([localeNamespace, "singular_legend"])}
          param={param}
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
        />
      )}
    </Filter>
  );
};
