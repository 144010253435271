import { t } from "i18n-js";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";

export interface BlockedActionInfoModalProps {
  title: string;
  info: string;
}

export const BlockedActionInfoModal = ({
  title,
  info,
}: BlockedActionInfoModalProps) => {
  const modal = useModal();
  const onClose = () => modal.hide();

  return (
    <ActionModal
      title={title}
      isOpen={modal.visible}
      onClose={onClose}
      actionText={t("paywalls.blocked_action_info_modal.got_it")}
      actionVariant="circle"
      onAction={onClose}
    >
      {info}
    </ActionModal>
  );
};
