import PropTypes from "prop-types";
import { t } from "i18n-js";
import { STATUS } from "@circle-react/helpers/communityMemberSubscriptionHelpers";
import { isInstallmentCompleted } from "@circle-react/helpers/communityMemberSubscriptionScheduleHelpers";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { isInstallmentsPrice } from "@circle-react/helpers/paywallPriceHelpers";
import { StatusBadge } from "@circle-react-uikit/StatusBadge";

const badgeStatus = {
  [STATUS.ACTIVE]: {
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.badge_status.active",
    ),
    variant: "success",
  },
  [STATUS.PAST_DUE]: {
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.badge_status.past_due",
    ),
    variant: "danger",
  },
  [STATUS.TRIAL]: {
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.badge_status.trial",
    ),
    variant: "success",
  },
  [STATUS.INCOMPLETE]: {
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.badge_status.incomplete",
    ),
    variant: "default",
  },
  [STATUS.CANCELED]: {
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.badge_status.canceled",
    ),
    variant: "default",
  },
  default: {
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.badge_status.unknown",
    ),
    variant: "default",
  },
};

export const PaywallSubscriptionBadge = ({ subscription }) => {
  const setBadgePointer = subscription => {
    if (
      isInstallmentsPrice(subscription.paywall_price) &&
      isInstallmentCompleted(
        subscription?.community_member_subscription_schedule,
      )
    ) {
      return {
        label: t(
          "settings.paywalls_admin_dashboard.subscriptions.badge_status.completed",
        ),
        variant: "success",
      };
    }

    if (subscription.cancels_on) {
      return {
        label: t(
          "settings.paywalls_admin_dashboard.subscriptions.badge_status.cancels_on",
          {
            date: formattedDateTime({
              dateTime: new Date(subscription.cancels_on),
              format: "short_month_and_date",
            }),
          },
        ),
      };
    }

    return badgeStatus[subscription.status] ?? badgeStatus["default"];
  };
  const badgePointer = setBadgePointer(subscription);

  return (
    <StatusBadge variant={badgePointer.variant}>
      {badgePointer.label}
    </StatusBadge>
  );
};

PaywallSubscriptionBadge.propTypes = {
  subscription: PropTypes.object.isRequired,
};
