import { useState } from "react";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { reactQueryDelete } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi, memberPaths } from "@circle-react/helpers/urlHelpers";
import {
  DropdownMenu_DEPRECATED,
  useDropdown,
} from "@circle-react-shared/DropdownMenu";
import { Icon } from "@circle-react-shared/Icon";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface SpaceOptionsDropdownProps {
  communityMember: any;
  showEditButton: boolean;
  showRemoveButton: boolean;
  space: any;
  refetch: () => void;
  renderAs?: "dropdown" | "link";
}

export const SpaceOptionsDropdown = ({
  communityMember,
  showEditButton,
  showRemoveButton,
  space,
  refetch,
  renderAs = "dropdown",
}: SpaceOptionsDropdownProps) => {
  const { open: isOpen, handleClose, toggleDropdown } = useDropdown();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const toastr = useToast();
  const localeScope = "members_directory.community_members.actions.space";

  const { mutate: deleteMutation, isLoading: isDeleting } = useMutation(
    () =>
      reactQueryDelete(
        internalApi.spaceMembers.leaveSpace({
          spaceId: space.id,
          memberId: communityMember.id,
        }),
      ),
    {
      onError: (error: { message: string }) => {
        toastr.error(error.message);
      },

      onSuccess: () => {
        toastr.success(t(`${localeScope}.successfully_removed`));
        setTimeout(() => {
          refetch();
        }, 1000);
      },

      onSettled: () => {
        handleConfirmClose();
      },
    },
  );

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
    handleClose();
  };

  const handleRemoveFromSpace = (e: any) => {
    e.preventDefault();
    setIsConfirmOpen(true);
  };

  const renderConfirmModal = () => (
    <ConfirmationModal
      isOpen={isConfirmOpen}
      title={t("remove")}
      onClose={handleConfirmClose}
      onCancel={handleConfirmClose}
      onConfirm={deleteMutation}
      confirmVariant="danger"
      confirmText={isDeleting ? t("deleting") : t("delete")}
      disabled={isDeleting}
    >
      {t(`${localeScope}.confirm_remove`, {
        community_member: communityMember.name,
        space: space.name,
      })}
    </ConfirmationModal>
  );

  if (!showRemoveButton && !showEditButton) {
    return null;
  }

  if (renderAs === "link" && showRemoveButton) {
    return (
      <>
        {renderConfirmModal()}
        <button type="button" className="btn" onClick={handleRemoveFromSpace}>
          {t(`${localeScope}.remove_from_space`)}
        </button>
      </>
    );
  }

  return (
    <>
      {renderConfirmModal()}
      <div className="community-member__options-dropdown">
        <button
          type="button"
          className="btn btn-secondary btn-small"
          onClick={toggleDropdown}
          aria-label={t("community_member.actions")}
        >
          <Icon type="menu-dots" />
        </button>
        <DropdownMenu_DEPRECATED
          className="dropdown-menu dropdown-menu--right animate-right"
          open={isOpen}
          handleClose={handleClose}
        >
          <div className="dropdown-menu__inside">
            <ul>
              {showEditButton && (
                <li>
                  <a
                    href={memberPaths.edit({
                      memberId: communityMember.id,
                    })}
                  >
                    {t("edit")}
                  </a>
                </li>
              )}
              {showRemoveButton && (
                <li>
                  <button
                    type="button"
                    className="w-full text-left"
                    onClick={handleRemoveFromSpace}
                  >
                    {t(`${localeScope}.remove_from_space`)}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </DropdownMenu_DEPRECATED>
      </div>
    </>
  );
};
