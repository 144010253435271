import { useEffect } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useSpaceApi } from "@/react/hooks/useSpaceApi";
import { useThemeContext } from "@/react/providers";
import { toLocaleString } from "@circle-react/helpers/number";
import { EmojiRenderer } from "@circle-react-shared/EmojiRenderer";

export const SpaceCard = ({
  id,
  url,
  emoji,
  customEmojiUrl,
  customEmojiDarkUrl,
  name,
  totalMembers,
  shouldHideMembersCount,
  handleJoinSpace,
}) => {
  const { isJoined, joinSpace } = useSpaceApi();
  const { currentAppearance } = useThemeContext();

  useEffect(() => {
    if (isJoined) {
      handleJoinSpace(id);
    }
  }, [isJoined]);

  return (
    <div className="space-card">
      <div className="space-card__inside">
        <div className="space-card__name">
          <div className="space-card__icon">
            <a href={url}>
              <EmojiRenderer
                className="title-emoji !h-10 !max-h-10 !w-10"
                emoji={emoji}
                appearance={currentAppearance}
                customEmojiUrl={customEmojiUrl}
                customEmojiDarkUrl={customEmojiDarkUrl}
              />
            </a>
          </div>
          <div className="space-card__title truncate">
            <a href={url}>
              <span className="title-name" data-testid="title-name">
                {name}
              </span>
            </a>
          </div>
        </div>
        <div className="space-card__members">
          {!shouldHideMembersCount && (
            <span>
              {t("settings.manage_members.member_count", {
                count: totalMembers,
                formattedCount: toLocaleString(totalMembers),
              })}
            </span>
          )}
        </div>
        <div className="space-card__actions">
          {isJoined ? (
            <span className="btn btn-secondary btn-secondary--joined">
              {t("settings.manage_members.joined")}
            </span>
          ) : (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => joinSpace(id)}
            >
              {t("join_space")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

SpaceCard.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  emoji: PropTypes.string,
  customEmojiUrl: PropTypes.string,
  customEmojiDarkUrl: PropTypes.string,
  name: PropTypes.string,
  totalMembers: PropTypes.number,
  handleJoinSpace: PropTypes.func,
};
