import { useMemo } from "react";
import { t } from "i18n-js";
import { isEmpty, isFunction } from "lodash";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import type { CheckboxFilterParam } from "@circle-react-shared/Filter/FilterTypes/CheckboxFilterV2";

interface SelectFieldFilterProps {
  param: CheckboxFilterParam;
  label: string;
  choices: Array<{ id: number; value: string }>;
  onApply: (value: CheckboxFilterParam[] | null) => void;
  isInitialOpen?: boolean;
}

export const SelectFieldFilter = ({
  label,
  choices,
  onApply,
  param,
  isInitialOpen = false,
}: SelectFieldFilterProps) => {
  const options = useMemo(
    () => choices.map(({ id, value }) => ({ label: value, value: String(id) })),
    [choices],
  );

  return (
    <Filter
      chip={label}
      title={label}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={options} param={param} />
      )}
      onApply={value => {
        if (isFunction(onApply)) {
          /* This has to be array value for the workflows app */
          if (isEmpty(value)) return onApply(null);
          onApply(value);
        }
      }}
      isInitialOpen={isInitialOpen}
      shouldShowRemoveButton
    >
      <SingleSelectFilter
        options={options}
        legend={label}
        param={param}
        hasSearch
        searchProps={{
          placeholder: t("search_item"),
        }}
      />
    </Filter>
  );
};
