import { t } from "i18n-js";
import { useAreAnalyticsFiltersEnabled } from "@circle-react/components/SettingsApp/AnalyticsV2/FilterBarV2/useAreAnalyticsFiltersEnabled";
import {
  UpgradeFlow,
  useIsProfileFieldsEnabled,
  useShouldShowProfileFieldsUpgradeModal,
} from "@circle-react-shared/ProfileFields";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { AddFilterDropdown } from "../AddFilterDropdown";
import { FiltersList } from "../AllFiltersList/FiltersList";
import { FilterContainer } from "../AvailableFiltersList/FilterContainer";
import { useDynamicFilterContext } from "../DynamicFilterContext";
import { useAvailableFilters } from "./useAvailableFilters";

interface AvailableFiltersListProps {
  label?: string;
  isFromAnalyticsFilterBar?: boolean;
}

export const AvailableFiltersList = ({
  label,
  isFromAnalyticsFilterBar = false,
}: AvailableFiltersListProps) => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const areAnalyticsFiltersEnabled = useAreAnalyticsFiltersEnabled();
  const shouldShowProfileFieldsUpgradeModal =
    useShouldShowProfileFieldsUpgradeModal();
  const { onAddFilter } = useDynamicFilterContext();

  const {
    availablePlatformFilters,
    availableProfileFieldFilters,
    hasAvailableFilters,
  } = useAvailableFilters();

  if (!hasAvailableFilters) return null;

  const shouldShowUpgradeFlow =
    (isFromAnalyticsFilterBar && !areAnalyticsFiltersEnabled) ||
    (!isFromAnalyticsFilterBar && shouldShowProfileFieldsUpgradeModal);

  const upgradeFlowSource = isFromAnalyticsFilterBar
    ? UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_ANALYTICS_FILTER_CHIP
    : UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_FILTER_CHIP;

  const shouldShowProfileFieldFilters =
    (!isFromAnalyticsFilterBar && isProfileFieldsEnabled) ||
    (isFromAnalyticsFilterBar && areAnalyticsFiltersEnabled);

  return (
    <AddFilterDropdown label={label}>
      <div className="flex max-h-96 flex-col gap-y-4 overflow-y-auto">
        <FiltersList
          title={t("filters.standard")}
          filters={availablePlatformFilters}
          onAddFilter={onAddFilter}
        />
        {shouldShowUpgradeFlow && (
          <FilterContainer title={t("profile_fields.upgrade_flow.title")}>
            <div className="mt-2 pl-4">
              <UpgradeFlow source={upgradeFlowSource} />
            </div>
          </FilterContainer>
        )}
        {shouldShowProfileFieldFilters && (
          <FiltersList
            title={t("filters.custom")}
            filters={availableProfileFieldFilters}
            onAddFilter={onAddFilter}
          />
        )}
      </div>
    </AddFilterDropdown>
  );
};
