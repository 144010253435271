import { useEffect } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { Form } from "@circle-react-uikit/Form";
import { Typography } from "@circle-react-uikit/Typography";
import { useExportCsvModalContext } from "./ExportCsvModalContext";
import { FIELD_OPTIONS_TYPE } from "./constants";

const getRelation = (relation: string) => {
  if (relation === "charges") return "community_member_charge";
  return "community_member_subscription";
};

export const SelectedFields = ({
  fieldOptionsType,
  defaultFields,
  allFields,
}: any) => {
  const { relation } = useExportCsvModalContext();
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const fieldOptionsToRender: any = {
    [FIELD_OPTIONS_TYPE.ALL]: {
      keys: allFields,
      labels: allFields
        .map((field: string) =>
          t(["export_paywall_csv", getRelation(relation), "fields", field]),
        )
        .join(", "),
    },
    [FIELD_OPTIONS_TYPE.DEFAULT]: {
      keys: defaultFields,
      labels: defaultFields
        .map((field: string) =>
          t(["export_paywall_csv", getRelation(relation), "fields", field]),
        )
        .join(", "),
    },
  };

  useEffect(() => {
    if (fieldOptionsType !== FIELD_OPTIONS_TYPE.CUSTOM) {
      setValue("fields", fieldOptionsToRender[fieldOptionsType].keys);
    }
  }, [fieldOptionsType, setValue]);

  const options = allFields.map((field: string) => ({
    label: t(["export_paywall_csv", getRelation(relation), "fields", field]),
    value: field,
  }));

  const hasErrorInFields = !!errors["fields"];

  if (fieldOptionsToRender[fieldOptionsType]) {
    return (
      <Typography.BodyMd color="text-default">
        {fieldOptionsToRender[fieldOptionsType].labels}
      </Typography.BodyMd>
    );
  }
  return (
    <div className="flex flex-col gap-y-2">
      <div
        className={classNames("grid-rows-12 grid grid-flow-col gap-y-3", {
          "rounded-lg border border-red-500 p-4": hasErrorInFields,
        })}
      >
        <Form.CheckboxGroup
          name="fields"
          options={options}
          itemClassName="!mt-0"
        />
      </div>
      {hasErrorInFields && (
        <p className="text-red-500">
          {t("export_paywall_csv.modal.options.validation_min_error")}
        </p>
      )}
    </div>
  );
};
