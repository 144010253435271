export const EmailPreviewThumbnail = () => (
  <svg
    id="icon-email-thumbnail"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_413_16658)">
      <rect
        width="56"
        height="56"
        rx="7"
        fill="var(--color-background-secondary)"
      />
      <rect width="56" height="60" transform="translate(0 1)" fill="white" />
      <rect
        x="6.32391"
        y="7.32391"
        width="43.3521"
        height="21.57"
        rx="0.988114"
        fill="var(--color-background-tertiary)"
      />
      <rect
        x="6.32391"
        y="32.0559"
        width="43.3521"
        height="2.76672"
        rx="0.592868"
        fill="var(--color-background-tertiary)"
      />
      <rect
        x="6.32367"
        y="37.9846"
        width="33.2006"
        height="2.76672"
        rx="0.592868"
        fill="var(--color-background-tertiary)"
      />
      <rect
        x="6.32391"
        y="43.9132"
        width="40"
        height="3"
        rx="0.592868"
        fill="var(--color-background-tertiary)"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="55"
      height="55"
      rx="6.5"
      stroke="var(--color-background-tertiary)"
    />
    <defs>
      <clipPath id="clip0_413_16658">
        <rect width="56" height="56" rx="7" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
