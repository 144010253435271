import { t } from "i18n-js";
import { LogoTopSectionView } from "@circle-react/components/Spaces/SidebarV2/LogoTopSection/LogoTopSectionView";
import { TopSectionContainer } from "@circle-react/components/Spaces/SidebarV2/LogoTopSection/TopSectionContainer";
import { DropdownWrapper } from "@circle-react-shared/uikit/HeaderV3/DropdownWrapper";
import { HeaderWrapperV3 } from "@circle-react-shared/uikit/HeaderV3/HeaderWrapperV3";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { NavigationBar } from "@circle-react-shared/uikit/HeaderV3/NavigationBar";
import { ProfileSectionDropdown } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/ProfileSectionDropdown";
import { SearchButton } from "@circle-react-shared/uikit/HeaderV3/RightActionBlock/SearchButton";

export interface HeaderV3PreviewProps {
  communityName: string;
  logoType?: "logo" | "icon";
  logoUrl?: string | null;
}

export const HeaderV3Preview = ({
  communityName = "My Community",
  logoType,
  logoUrl,
}: HeaderV3PreviewProps) => (
  <HeaderWrapperV3>
    <TopSectionContainer
      hasBorder
      hasPadding={false}
      isSticky={false}
      className="pointer-events-none absolute left-5"
    >
      <LogoTopSectionView
        communityName={communityName}
        logoType={logoType || "icon"}
        logoUrl={logoUrl}
        showToggleTheme
      />
    </TopSectionContainer>
    <NavigationBar isPreviewMode />
    <DropdownWrapper className="justify-end gap-4">
      <SearchButton isDisabled />
      <div className="flex items-center">
        <IconButton
          name="20-bell-v3"
          iconSize={20}
          buttonSize={36}
          iconClassName="!text-c-header"
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
          disabled
          ariaLabel={t("header_v3.notifications")}
        />
        <IconButton
          name="20-message-v3"
          iconSize={20}
          buttonSize={36}
          iconClassName="!text-c-header"
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
          disabled
          ariaLabel={t("header_v3.direct_messages")}
        />
        <IconButton
          name="20-thread-v3"
          iconSize={20}
          buttonSize={36}
          iconClassName="!text-c-header"
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
          disabled
          ariaLabel={t("header_v3.chat_threads")}
        />
        <IconButton
          name="20-bookmark-v3"
          iconSize={20}
          buttonSize={36}
          iconClassName="!text-c-header"
          className="hover:!bg-c-header-hover focus-visible:!bg-c-header-hover"
          disabled
          ariaLabel={t("header_v3.bookmarks")}
        />
      </div>
      <ProfileSectionDropdown isPreviewMode />
    </DropdownWrapper>
  </HeaderWrapperV3>
);
