import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { Button } from "@circle-react-uikit/Button";
import { Fieldset } from "@circle-react-uikit/Fieldset";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../Filter";

export const RadioFilter = ({ options, name, legend }) => {
  const { selectedText, onApply } = useFilterContext();
  const primaryButtonVariant = useButtonVariant();

  const handleApply = (submitData, { reset }) => {
    onApply(submitData[name]);
    reset();
  };

  return (
    <div className="min-w-[18rem] p-4">
      <Form defaultValues={{ [name]: selectedText }} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-4">
          <Fieldset legend={legend} verticalSpacing="tight">
            <Form.Item
              name={name}
              hideLabel
              hideBorder
              hideDescription
              isFirstChild
            >
              <Form.RadioGroup variant="radio" options={options} />
            </Form.Item>
          </Fieldset>
          <Button variant={primaryButtonVariant} type="submit" full>
            {t("filters.apply")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

RadioFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  ).isRequired,
  legend: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};
