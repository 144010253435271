import type { ReactNode } from "react";
import { Typography } from "@circle-react-uikit/Typography";

interface GroupTitleProps {
  children: ReactNode;
}

export const GroupTitle = ({ children }: GroupTitleProps) => (
  <div className="px-4">
    <Typography.LabelXxs
      textTransform="uppercase"
      color="text-light"
      weight="semibold"
    >
      {children}
    </Typography.LabelXxs>
  </div>
);
