import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { SpaceGroupList } from "./SpaceGroupList";

const animations = {
  enter: "transition ease-in duration-75",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition ease-out duration-75",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

export const SpaceGroupsDropdown = ({
  spaceGroups,
  selectedSpaceGroup,
  onChange,
  appendTo,
}) => (
  <Dropdown
    size="full"
    button={
      <div
        className="flex w-full items-center justify-between text-left"
        data-testid="space-group-selector"
      >
        <Typography.LabelMd>
          {selectedSpaceGroup?.name || t("select_a_space_group")}
        </Typography.LabelMd>
        <Icon className="text-c-sidebar" size={16} type="caret-down-bold" />
      </div>
    }
    popperOptions={{
      strategy: "absolute",
    }}
    direction="bottom"
    className="border-primary focus-within:border-light w-full rounded-lg border transition-colors"
    buttonWrapperClassName="flex w-full h-full"
    buttonClassName="py-3 pl-4 pr-2 w-full"
    menuWrapperClassName="!px-0"
    appendTo={appendTo}
    animations={animations}
  >
    <SpaceGroupList spaceGroups={spaceGroups} handleChange={onChange} />
  </Dropdown>
);

SpaceGroupsDropdown.propTypes = {
  spaceGroups: PropTypes.array.isRequired,
  selectedSpaceGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  appendTo: PropTypes.node,
};
