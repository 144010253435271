import { StandardLayoutHeader } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeader";
import type { Space } from "@circle-react/types";
import { LeftContent } from "./LeftContent";
import { RightContent } from "./RightContent";

export interface HeaderPreviewProps {
  spaceName?: string;
  isPrivate?: boolean;
  emoji?: string;
  hideRightContent?: boolean;
}

export const HeaderPreview = ({
  spaceName = "",
  isPrivate = false,
  emoji,
  hideRightContent = false,
}: HeaderPreviewProps) => {
  const previewSpace: Space = {
    cover_image_visible: false,
    current_user_roles: [],
    emoji: emoji,
    hide_from_featured_areas: false,
    id: 1,
    is_private: isPrivate,
    is_space_member: false,
    name: spaceName,
    post_type: "basic",
    slug: "",
    space_group_id: 1,
  };

  return (
    <StandardLayoutHeader
      hasBorder
      className="pointer-events-none"
      leftContent={<LeftContent space={previewSpace} />}
      rightContent={!hideRightContent && <RightContent />}
    />
  );
};
