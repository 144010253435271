import { t } from "i18n-js";
import { doesBelongToMobilePlatform } from "@circle-react/helpers/communityMemberChargeHelpers";
import { useBlockedActionInfoModal } from "@circle-react-shared/Paywalls/Modals/BlockedActionInfoModal";
import { useChargeDetailsModal } from "@circle-react-shared/Paywalls/Modals/ChargeDetailsModal";
import { useCancelAndRefundChargeModal } from "@circle-react-shared/Paywalls/Modals/RefundChargeModal";
import { useAttributeSaleMenuItem } from "@circle-react-shared/Paywalls/hooks/useAttributeSaleMenuItem";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { usePaywallChargesListContext } from "../PaywallChargesListContextProvider";

interface MenuItemProp {
  key: string;
  label: string;
  onClick: (event: any) => void;
}

export const ActionMenuColumn = (cellContext: any) => {
  const { contentPortalElement } = useModalData();

  const {
    row: { original: charge },
  } = cellContext;

  const {
    shouldOpenAdminMemberEdit,
    variant: chargeListVariant,
    refetchAllCharges,
  } = usePaywallChargesListContext();

  const isAdminMemberEditClickable =
    shouldOpenAdminMemberEdit && !!charge.community_member.public_uid;

  // Modals
  const chargeDetailsModal = useChargeDetailsModal(chargeListVariant);
  const refundModal = useCancelAndRefundChargeModal();
  const attributeSaleMenuItem = useAttributeSaleMenuItem({
    attributable: charge,
    attributableType: "CommunityMemberCharge",
    onSuccess: refetchAllCharges,
  });
  const blockedActionInfoModal = useBlockedActionInfoModal();

  const menuItems: MenuItemProp[] = [];
  menuItems.push({
    key: "charge-details",
    label: t(
      "settings.paywalls_admin_dashboard.charges.list.dropdown_menu.details",
    ),
    onClick: e => {
      void chargeDetailsModal.show({
        charge,
        shouldOpenAdminMemberEdit: isAdminMemberEditClickable,
      });
      e.stopPropagation();
    },
  });

  if (attributeSaleMenuItem) menuItems.push(attributeSaleMenuItem);

  if (charge?.policies?.is_refundable) {
    const onMobile = (e: any) => {
      void blockedActionInfoModal.show({
        title: t(
          "settings.paywalls_admin_dashboard.charges.refund_modal.title",
        ),
        info: t(
          `settings.paywalls_admin_dashboard.charges.refund_modal.blocked_action_info.platform.${charge.platform}`,
        ),
      });
      e.stopPropagation();
    };

    const onWeb = (e: any) => {
      void refundModal.show({ charge, onSuccess: refetchAllCharges });
      e.stopPropagation();
    };

    menuItems.push({
      key: "charge-refund",
      label: t(
        "settings.paywalls_admin_dashboard.charges.list.dropdown_menu.refund_payment",
      ),
      onClick: doesBelongToMobilePlatform(charge) ? onMobile : onWeb,
    });
  }

  return (
    <DataTable.Actions
      contentPortalElement={contentPortalElement ?? document.body}
    >
      {menuItems.map(({ key, label, onClick }) => (
        <DataTable.Actions.Item key={key} onClick={onClick}>
          {label}
        </DataTable.Actions.Item>
      ))}
    </DataTable.Actions>
  );
};
