import { t } from "i18n-js";
import { isFunction } from "lodash";
import {
  Filter,
  RadioFilterV2 as RadioFilter,
} from "@circle-react-shared/Filter";
import type {
  RadioFilterOptions,
  RadioFilterParam,
} from "@circle-react-shared/Filter/FilterTypes/RadioFilterV2";

const localeNamespace = "members_directory.header.search.filters";

const roleOptions: RadioFilterOptions = [
  { label: t("role_admin"), value: "Admin" },
  { label: t("moderator"), value: "Moderator" },
  { label: t("member"), value: "Member" },
];

interface RoleFilterOptions {
  isInitialOpen?: boolean;
  onApply?: (value: string) => void;
  param: RadioFilterParam;
}

export const RoleFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: RoleFilterOptions) => (
  <Filter
    chip={t([localeNamespace, "role"])}
    title={t([localeNamespace, "role"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <RadioFilter
      options={roleOptions}
      legend={t([localeNamespace, "role_legend"])}
      param={param}
    />
  </Filter>
);
