import { differenceInDays } from "date-fns";
import { t } from "i18n-js";
import { useCurrentPlanApi } from "@circle-react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeRoot = "marketing_hub_trial_banner";

const dateWithoutTimeStamp = (date: Date) => new Date(date.toDateString());

export const MarketingHubFreePeriodBanner = () => {
  const redirectToBillingPage = () => {
    window.open(settingsNavPath.plans.billing, "_blank");
  };

  const { currentPlan, isCurrentPlanLoading, errorFecthingCurrentPlan } =
    useCurrentPlanApi();

  const isInFreePeriod =
    currentPlan?.marketing_hub_billing?.free_period?.active;
  const isEnabled =
    currentPlan?.marketing_hub_billing?.marketing_hub_billing_active;

  if (
    isCurrentPlanLoading ||
    errorFecthingCurrentPlan ||
    !currentPlan ||
    !isEnabled ||
    !isInFreePeriod
  ) {
    return null;
  }

  const marketingHubFreePeriod =
    currentPlan?.marketing_hub_billing?.free_period;
  const endsAt = marketingHubFreePeriod?.ends_at;

  const today = dateWithoutTimeStamp(new Date());
  const freePeriodEndsAt = dateWithoutTimeStamp(new Date(endsAt));
  const daysLeft = Math.max(differenceInDays(freePeriodEndsAt, today), 0);

  return (
    <div className="bg-secondary flex flex-col gap-y-3 rounded-xl p-4">
      <div className="flex flex-col gap-y-1">
        <Typography.LabelMd weight="bold">
          <span className="bg-marketing-hub-title inline-block bg-clip-text text-transparent">
            {t([localeRoot, "title"])}
          </span>
        </Typography.LabelMd>
        <Typography.LabelSm weight="bold">
          {t([localeRoot, "subtitle"], { count: daysLeft })}
        </Typography.LabelSm>
      </div>
      <Typography.LabelXs color="text-default">
        {t([localeRoot, "description"], {
          start_date: formattedDateTime({
            dateTime: freePeriodEndsAt,
            format: dateFormats.long_date,
          }),
        })}
      </Typography.LabelXs>
      <Button variant="secondary" onClick={redirectToBillingPage}>
        {t([localeRoot, "cta"])}
      </Button>
    </div>
  );
};
