import classNames from "classnames";
import { t } from "i18n-js";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface WithIconProps {
  iconUrl: string;
  name: string;
}

export const WithIcon = ({ iconUrl, name }: WithIconProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("brand-icon brand-icon__image", {
        "!h-8 !w-8 !rounded-lg !outline-none": isV3Enabled,
      })}
    >
      <img
        loading="eager"
        className={classNames({
          "rounded-lg": !isV3Enabled,
          "!max-w-8 !max-h-8 rounded-lg": isV3Enabled,
        })}
        alt={t("community_logo_alt", { community_name: name })}
        src={iconUrl}
        style={{ width: "32", height: "32" }}
      />
    </div>
  );
};
