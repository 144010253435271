import { isFunction } from "lodash";
import invariant from "tiny-invariant";
import { Filter, InputFilterComparator } from "@circle-react-shared/Filter";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator";
import type { StringComparatorProfileFieldParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { isProfileFieldBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { ProfileFieldInputs } from "./ProfileFieldInputs";

interface TextFieldFilterProps {
  label: string;
  param: BaseFilter;
  onApply?: (value: StringComparatorProfileFieldParam) => void;
  isInitialOpen?: boolean;
}

export const TextFieldFilter = ({
  label,
  param,
  onApply,
  isInitialOpen,
}: TextFieldFilterProps) => {
  invariant(
    isProfileFieldBaseFilter(param),
    "param must be a profile field filter",
  );

  return (
    <Filter
      chip={label}
      title={label}
      selectedText={param.value}
      onApply={(param: StringComparatorProfileFieldParam) => {
        if (isFunction(onApply)) {
          onApply(param);
        }
      }}
      isInitialOpen={isInitialOpen}
      shouldShowRemoveButton
      renderSelectedText={() => renderTextSelectedItem(param)}
    >
      <InputFilterComparator name="profile_field" param={param}>
        <ProfileFieldInputs {...param} />
      </InputFilterComparator>
    </Filter>
  );
};
