import { t } from "i18n-js";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const SiteLinks = () => {
  const links: Link[] = [
    {
      label: t("settings_nav_v3.site.navigation"),
      path: settingsNavPathV3.site.navigation,
      exact: true,
      isNewFeature: true,
    },
    {
      label: t("settings_nav_v3.site.defaults"),
      path: settingsNavPathV3.site.index,
      exact: true,
    },
    {
      label: t("settings_nav_v3.site.spaces"),
      path: settingsNavPathV3.site.spaces,
      exact: true,
    },
    {
      label: t("settings_nav_v3.site.code_snippets"),
      path: settingsNavPathV3.site.code_snippets,
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.site.label")}>
        {links.map(link => (
          <SubNavLinkItem
            key={link.path}
            label={link.label}
            path={link.path}
            isNewFeature={!!link.isNewFeature}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
