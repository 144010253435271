import PropTypes from "prop-types";
import { t } from "i18n-js";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { useResumePaywallSubscriptionModal } from "./hooks/useResumePaywallSubscriptionModal";

const LOCALES_ROOT =
  "settings.paywalls_admin_dashboard.subscriptions.resume_subscription_modal";
const formId = "paywall_resume-subscription";

export const ResumePaywallSubscriptionModal = ({ subscription, onSuccess }) => {
  const { handleSubmit, modal, onClose, isSubmitting } =
    useResumePaywallSubscriptionModal({
      subscription,
      onSuccess,
    });

  const descriptionText = t(`${LOCALES_ROOT}.description`, {
    date: formattedDateTime({
      dateTime: new Date(subscription.renews_at),
      format: "long_month_and_date",
    }),
  });

  return (
    <ActionModal
      title={t(`${LOCALES_ROOT}.title`)}
      isOpen={modal.visible}
      onAction={handleSubmit}
      onClose={onClose}
      formId={formId}
      disabled={isSubmitting}
      actionText={t(`${LOCALES_ROOT}.resume_button`)}
    >
      <div className="pb-4 pt-2 text-center">{descriptionText}</div>
    </ActionModal>
  );
};

ResumePaywallSubscriptionModal.propTypes = {
  subscription: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
