import { useState } from "react";
import { t } from "i18n-js";
import { useMutation } from "react-query";
import { affiliatesApi } from "@circle-react/api/paywalls/affiliatesApi";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { isSaleAttributed } from "@circle-react/helpers/paywalls/affiliates/helpers";
import type {
  Attributable,
  AttributableType,
} from "@circle-react/types/PaywallsAffiliates";
import { useToast } from "@circle-react-uikit/ToastV2";

interface CreateAttributionDataType {
  attributable_type: AttributableType;
  attributable_id: number;
  affiliate_id: number | null;
}

interface DestroyAttributionDataType {
  attributable_type: AttributableType;
  attributable_id: number;
}

interface AttributeSaleProps {
  attributable: Attributable;
  attributableType: AttributableType;
  onSuccess: () => void;
}

export const useAttributeSale = ({
  attributable,
  attributableType,
  onSuccess,
}: AttributeSaleProps) => {
  const modal = useModal();
  const [affiliateId, setAffiliateId] = useState<number | null>(null);
  const [
    isRemoveAffiliateAttributionConfirmationModalOpen,
    setIsRemoveAffiliateAttributionConfirmationModalOpen,
  ] = useState<boolean>(false);

  const { success, error } = useToast();

  const openRemoveAffiliateAttributionConfirmationModal = () =>
    setIsRemoveAffiliateAttributionConfirmationModalOpen(true);
  const closeRemoveAffiliateAttributionConfirmationModal = () =>
    setIsRemoveAffiliateAttributionConfirmationModalOpen(false);

  const onClose = () => {
    void modal.hide();

    modal.remove();
  };

  const actionText = isSaleAttributed(attributable)
    ? ""
    : t(
        "settings.paywalls_admin_dashboard.attribute_sale_modal.confirm_button",
      );

  const onAction = () =>
    createMutation.mutate({
      attributable_type: attributableType,
      attributable_id: attributable.id,
      affiliate_id: affiliateId,
    });

  const createMutation = useMutation<
    CreateAttributionDataType,
    ApiError,
    CreateAttributionDataType
  >(data => affiliatesApi.attributions.create(data), {
    onSuccess: () => {
      success(
        t(
          "settings.paywalls_admin_dashboard.attribute_sale_modal.alerts.success",
        ),
      );
      onClose();
      onSuccess();
    },
    onError: () => {
      error(
        t(
          "settings.paywalls_admin_dashboard.services.alerts.error.unexpected_error",
        ),
        { duration: "long" },
      );
    },
  });

  const removalNotAllowedAlert = attributable.has_removable_attribution
    ? null
    : t(
        "settings.paywalls_admin_dashboard.attribute_sale_modal.alerts.errors.removal_not_allowed",
      );

  const onRemove = () =>
    destroyMutation.mutate({
      attributable_type: attributableType,
      attributable_id: attributable.id,
    });

  const destroyMutation = useMutation<
    DestroyAttributionDataType,
    ApiError,
    DestroyAttributionDataType
  >(data => affiliatesApi.attributions.destroy(data), {
    onSuccess: () => {
      success(
        t(
          "settings.paywalls_admin_dashboard.attribute_sale_modal.alerts.removal.success",
        ),
      );
      onClose();
      closeRemoveAffiliateAttributionConfirmationModal();
      onSuccess();
    },
    onError: () => {
      error(
        t(
          "settings.paywalls_admin_dashboard.services.alerts.error.unexpected_error",
        ),
        { duration: "long" },
      );
    },
  });

  return {
    modal,
    onClose,
    affiliateId,
    setAffiliateId,
    actionText,
    removalNotAllowedAlert,
    onAction,
    onRemove,
    createMutation,
    destroyMutation,
    isRemoveAffiliateAttributionConfirmationModalOpen,
    openRemoveAffiliateAttributionConfirmationModal,
    closeRemoveAffiliateAttributionConfirmationModal,
  };
};
