import { useMemo } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import {
  UpgradeFlow,
  useIsProfileFieldsEnabled,
  useShouldShowProfileFieldsUpgradeModal,
} from "@circle-react-shared/ProfileFields";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { AddFilterDropdown } from "../AddFilterDropdown";
import { FilterContainer } from "./FilterContainer";
import { FiltersList } from "./FiltersList";

export const AvailableFiltersList = ({
  defaultFilters = [],
  customFilters = [],
  availableFilters = [],
  onAddFilter,
  label,
}) => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const shouldShowProfileFieldsUpgradeModal =
    useShouldShowProfileFieldsUpgradeModal();
  const {
    defaultFilters: availableDefaultFilters,
    customFilters: availableCustomFilters,
  } = useMemo(() => {
    const availableFiltersSet = new Set(
      availableFilters.map(({ name }) => name),
    );
    const getAvailable = ({ name }) => availableFiltersSet.has(name);
    return {
      defaultFilters: defaultFilters.filter(getAvailable),
      customFilters: customFilters.filter(getAvailable),
    };
  }, [availableFilters, defaultFilters, customFilters]);

  return (
    <AddFilterDropdown label={label}>
      <div className="flex max-h-96 flex-col gap-y-4 overflow-y-auto">
        <FiltersList
          title={t("filters.standard")}
          filters={availableDefaultFilters}
          onAddFilter={onAddFilter}
        />
        {shouldShowProfileFieldsUpgradeModal && (
          <FilterContainer title={t("profile_fields.upgrade_flow.title")}>
            <div className="mt-2 pl-4">
              <UpgradeFlow
                source={UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_FILTER_CHIP}
              />
            </div>
          </FilterContainer>
        )}
        {isProfileFieldsEnabled && (
          <FiltersList
            title={t("filters.custom")}
            filters={availableCustomFilters}
            onAddFilter={onAddFilter}
          />
        )}
      </div>
    </AddFilterDropdown>
  );
};

const FilterArrayShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
});

AvailableFiltersList.propTypes = {
  availableFilters: PropTypes.arrayOf(FilterArrayShape),
  defaultFilters: PropTypes.arrayOf(FilterArrayShape),
  customFilters: PropTypes.arrayOf(FilterArrayShape),
  onAddFilter: PropTypes.func.isRequired,
  label: PropTypes.string,
};
