import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { t } from "i18n-js";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/Filter/Filter";
import { BaseFilterFields } from "@circle-react-shared/Filter/FilterTypes/BaseFilterFields";
import { SearchBar } from "@circle-react-shared/Filter/FilterTypes/CheckboxFilter";
import { SelectFilterOption } from "@circle-react-shared/Filter/FilterTypes/SingleSelectFilter";
import type { SingleSelectFilterOption } from "@circle-react-shared/Filter/FilterTypes/SingleSelectFilter/SelectFilterOption";
import { getIsSingleSelectFilterParam } from "@circle-react-shared/Filter/FilterTypes/SingleSelectFilter/SingleSelectFilter";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { GroupTitle } from "./GroupTitle";

export interface SingleSelectFilterParam extends BaseFilter {
  value?: number[];
}

interface SingleSelectFilterFormProps {
  upcomingEvents?: SingleSelectFilterOption[];
  pastEvents?: SingleSelectFilterOption[];
  legend: string;
  searchProps?: {
    placeholder: string;
    label?: string;
  };
  param: BaseFilter;
}

export const SingleSelectFilterForm = ({
  upcomingEvents = [],
  pastEvents = [],
  legend,
  searchProps,
  param,
}: SingleSelectFilterFormProps) => {
  const { onApply } = useFilterContext();
  const [searchTerm, setSearchTerm] = useState("");
  const primaryButtonVariant = useButtonVariant();

  const searchFilter = (option: SingleSelectFilterOption) =>
    !searchTerm
      ? option
      : option.label.toLowerCase().includes(searchTerm.toLowerCase());

  const filteredUpcomingEvents = upcomingEvents.filter(searchFilter);
  const filteredPastEvents = pastEvents.filter(searchFilter);

  const getDefaultValues = (): any => ({
    ...param,
    value: getIsSingleSelectFilterParam(param) ? param.value : undefined,
  });

  const [selectedValue, setSelectedValue] = useState(getDefaultValues().value);

  const handleApply = (submitData: SingleSelectFilterParam, { reset }: any) => {
    onApply({ ...submitData, value: selectedValue });
    reset();
  };

  return (
    <div className="flex flex-col gap-y-3 py-4">
      <div className="px-4">
        <SearchBar
          name="events-search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          searchProps={searchProps}
        />
      </div>
      <Form defaultValues={getDefaultValues()} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-4">
          <BaseFilterFields />
          <RadioGroup
            value={selectedValue}
            onChange={setSelectedValue}
            name="value"
          >
            <RadioGroup.Label className="sr-only">{legend}</RadioGroup.Label>
            <div className="flex max-h-[20rem] flex-col overflow-y-auto">
              {filteredUpcomingEvents.length > 0 && (
                <>
                  <GroupTitle>{t("upcoming_events")}</GroupTitle>
                  {filteredUpcomingEvents.map(option => (
                    <SelectFilterOption key={option.value} option={option} />
                  ))}
                </>
              )}
              {filteredPastEvents.length > 0 && (
                <>
                  <div className="mb-2 mt-4">
                    <GroupTitle>{t("past_events")}</GroupTitle>
                  </div>
                  {filteredPastEvents.map(option => (
                    <SelectFilterOption key={option.value} option={option} />
                  ))}
                </>
              )}
            </div>
          </RadioGroup>
          <div className="px-4">
            <Button variant={primaryButtonVariant} type="submit" full>
              {t("filters.apply")}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
