import { t } from "i18n-js";
import { isFunction } from "lodash";
import {
  Filter,
  RadioFilterV2 as RadioFilter,
} from "@circle-react-shared/Filter";
import type {
  RadioFilterOptions,
  RadioFilterParam,
} from "@circle-react-shared/Filter/FilterTypes/RadioFilterV2";

const localeNamespace = "members_directory.header.search.filters";

const statusOptions: RadioFilterOptions = [
  { label: t("online"), value: "Online" },
  { label: t("offline"), value: "Offline" },
];

interface StatusFilterOptions {
  isInitialOpen?: boolean;
  onApply?: (value: RadioFilterParam) => void;
  param: RadioFilterParam;
}

export const StatusFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: StatusFilterOptions) => (
  <Filter
    chip={t([localeNamespace, "online_status"])}
    title={t([localeNamespace, "online_status"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <RadioFilter
      param={param}
      options={statusOptions}
      legend={t([localeNamespace, "status_legend"])}
    />
  </Filter>
);
